import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="tungsten" title="Duplex"/>
      <Section>
        <p>
          Duplex is stainless steel that has a two-phase microstructure consisting the combination of ferritic and austenitic structures. This combination creates many beneficial properties of ferritic and austenitic stainless steels. The duplex microstructure contributes to the high mechanical strength and high resistance to stress corrosion cracking. The high strength of duplex steel grades is also a result of alloying with nitrogen.
        </p>
        <p>
          Duplex becomes more common and popular because of its benefits in applications:
        </p>

        <ul>
          <li>Heat exchangers</li>
          <li>Pressure vessels</li>
          <li>Cooling tubes and pipes</li>
          <li>Chemical processing and storage tanks</li>
          <li>Oil and gas exploration and processing equipment</li>
        </ul>

        <p>
          The benefits of using duplex stainless steel:
        </p>
        <ul>
          <li>Excellent corrosion resistance, especially stress corrosion cracking, corrosion fatigue and erosion</li>
          <li>Heat resistance and lower thermal expansion co-efficient than austenitic steels</li>
          <li>Good weldability, notably in thick sections, particularly from welding process through any welding methods</li>
          <li>Higher strength, particularly range of 0.2% PS for the current duplex grades 400 to 550 MPa. This can reduce sections thickness and therefore brings to reduce weight for the weight advantages</li>
        </ul>
      </Section>
    </Article>
  )
}
