import React from 'react';
import styled from 'styled-components';
import { height, backgroundImage, backgroundPosition, HeightProps, BackgroundImageProps, BackgroundPositionProps, WidthProps, width, color } from 'styled-system';
import { Flex } from 'components';
import { ColorProps } from 'components/types/style-system';


//---------------
//  Banner
//---------------
type BannerProps = ColorProps & WidthProps & HeightProps & BackgroundImageProps & BackgroundPositionProps & {
  title?: string,
  children?: React.ReactNode,
}

const BannerWrapper = styled.div<BannerProps>`
  width:100%;
  background-size: cover;
  min-height: 300px;
  h2{
    color: inherit;
    display: block;
    font-size: 2.5rem;
    margin:0;
    text-align: center;
    font-weight: 300;
  }
  ${color}
  ${height}
  ${width}
  ${backgroundImage}
  ${backgroundPosition}
`

export function Banner(props:BannerProps){
  const { title, children, ...bannerProps } = props;
  return (
    <BannerWrapper {...bannerProps}>
      {title && (
        <Flex height="100%" width="100%" alignItems="center" justifyContent="center">
          <Section>
            <h2>{title}</h2>
          </Section>
        </Flex>
      )}
      {children}
    </BannerWrapper>
  );
}

Banner.defaultProps = {
  height: [300, 400],
  width: "100%",
  color: 'rgba(255,255,255,.9)',
}



//---------------
//  Content
//---------------
export const Article = styled.article<HeightProps>`
  width: 100%;
  min-height: 800px;
  padding-top: 56px;
  h3, h4, h5, h6, blockquote {
    font-family: 'Merriweather', serif;
  }

  h3{
    font-size: 2rem;
    margin: 1em 0;
  }

  h4{
    font-size: 1.5rem;
    margin: 1em 0;
  }

  h6{
    font-size: 1rem;
    line-height: 1.8;
  }

  p{
    letter-spacing: ${0.5/16}rem;
    line-height: 1.5;
  }

  ul{
    list-style-position: inside;
    li{
      letter-spacing: ${-0.15/16}rem;
      line-height: 1.5;
      margin:1rem 0;
    }
  }

  ${height}

`

//---------------
//  Content
//---------------
type SectionProps = WidthProps;

export const Section = styled.section<SectionProps & ColorProps>`
  padding: 1rem;
  margin: 0 auto;
  ${width}
  ${color}
`

Section.defaultProps = {
  width: ['100%', '100%', '960px'],
}
