import {styleFn, createMediaQuery} from 'styled-system';

const responsive:styleFn = (props: any)=>{
  const { theme, responsive:value } = props;
  const { breakpoints } = theme;
  if (Array.isArray(value)) {
    let styles = {...value[0]};
    for (let i = 1; i < value.slice(0, breakpoints.length + 1).length; i++) {
      let media = createMediaQuery(breakpoints[i - 1]);
      styles = {...styles, [media]: value[i]}
    }
    return styles;
  }
}

export default responsive;
