import React, {useRef} from 'react';
import styled from 'styled-components';
import { View } from 'components';
import interpolation from 'components/utils/interpolation';
import { Screen, Mask, PosProps, H1, Subtitle, ContentRoot } from '../common/components';


export default function Screen06(props:PosProps){
  const { pos } = props;
  const video = useRef(null);

  if(video.current !== null && pos){
    const videoRef:any = video.current;

    if(pos > 0 && videoRef.paused){
      videoRef.play();
    }

    if((pos < -0.5 || pos > 1) && !videoRef.paused){
      videoRef.pause();
    }
  }

  return (
    <Screen>
      <Video ref={video} muted loop id="myVideo" >
        <source src="/assets/beach.mp4" type="video/mp4"/>
      </Video>
      <Mask bg="cyan5" opacity={0.6}/>
    </Screen>
  );
}


export function Content(props:PosProps){
  return (
    <ContentRoot color="white">
      <View p='2rem' opacity={interpolation([[0,1],[0.5,0]])(props.pos)}>
        <H1>
          Yes, We are part of <b>Brand Tasmania</b>
        </H1>
        <Subtitle color="gray1">
          We are member of <b>Brand Tasmania</b>, the island state of Australia. Generally, we source products from Tasmania and cities across Australia, Singapore, Malaysia, Japan, China, South Korea, Germany, Spain, Italy, and other European approved mills.         </Subtitle>
      </View>
    </ContentRoot>
  )
}

const Video = styled.video`
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
