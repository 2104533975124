import styled, { keyframes } from 'styled-components';
import { color, space, width, height, SpaceProps, ColorProps, WidthProps, HeightProps } from 'styled-system';
import { size, Size } from './stylefn';

//====================
// Rotate Plane
//====================
type RotatePlaceProps = SpaceProps & ColorProps & WidthProps & HeightProps &{
  size?: Size
}

const rotateplaneAnim = keyframes`
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
`

const RotatePlane = styled('div') <RotatePlaceProps> `
  animation: ${rotateplaneAnim} 1.2s infinite ease-in-out;
  ${color}
  ${space}
  ${size}
  ${width}
  ${height}
`

RotatePlane.defaultProps = {
  bg: 'gray',
  size: 's',
}

export default RotatePlane
