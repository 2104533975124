import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import ga from 'ga';
import Pages from './pages';
const history = require('history');

export default function App(){
  const browserHistory = history.createBrowserHistory();
  useEffect(()=>{
    ga.pageView(browserHistory.location)
    return browserHistory.listen((location:any)=>{
      ga.pageView(location)
    });
  },[browserHistory]);

  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Pages/>
      </Router>
    </ThemeProvider>
  );
}
