import React from 'react';
import { NavPage } from '../common/Pages';
import Footer from '../common/Footer';
import { products, materials } from './data';
import { Redirect } from 'react-router';

const all = [...products, ...materials];

type ProductProps = {
  match: any,
}

export default function Product(props:ProductProps){
  const { productId } = props.match.params;
  const product = all.find(product=>product.id === productId);
  if(!product){
    return (
      <Redirect to="/products"/>
    );
  }

  const {content: Content} = product;

  return (
    <NavPage>
      {Content && (<Content/>)}
      <Footer/>
    </NavPage>
  );
}
