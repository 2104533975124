import React from 'react';
import range from 'lodash/range';
import styled, { keyframes } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { colorAsBackground, size, Size } from './stylefn';

//====================
// rotate dot
//====================
type RotateDotProps = SpaceProps & {
  size?: Size,
  color? :string,
}

function RotateDotBase(props: RotateDotProps & {className?: string}){
  return (
    <div className={props.className}>
      {range(0, 2).map(i=><span key={i}/>)}
    </div>
  );
}

const rotateDot = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const bounceDot = keyframes`
  0%, 100% {
    transform: scale(0.0);
    opacity: 0.1;
  } 50% {
    transform: scale(1.0);
    opacity: 1;
  }
`
const RotateDot = styled(RotateDotBase)<RotateDotProps>`
  position: relative;
  display: inline-block;
  background-color: transparent;
  text-align: center;
  animation: ${rotateDot} 2.0s infinite linear;
  ${size}
  ${space}
  & > span{
    position: absolute;
    display: inline-block;
    top:0;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    ${colorAsBackground}
    animation: ${bounceDot} 2.0s infinite ease-in-out;
    ${()=>range(1,2).reduce((styles, i)=>{
      const key = `&:nth-child(${i})`
      const delay = 0.5 + i/2;
      return {
        ...styles,
        [key] : {
          top: 'auto',
          bottom: 0,
          animationDelay: `-${delay}s`
        }
      }
    },{})}
  }
`

RotateDot.defaultProps = {
  color: 'gray8',
  size: 's',
}

export default RotateDot;
