import React, { useState, useRef, useEffect, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { space, color, SpaceProps } from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import Icon from '../icon'
import { IconButton } from '../buttons'
import hover, { HoverProps } from '../utils/stylefn/hover';


/**
 * Popup
 */
export type AlignX = 'left' | 'right' | 'center' | 'fill'

export type AlignY = 'top' | 'below' | 'bottom' | 'above' | 'middle'

type PopupProps = {
  active: boolean,
  alignx?: AlignX,
  aligny?: AlignY,
}

const Popup = styled.section`
  position: absolute;
  z-index: 1000;
  ${(props : PopupProps)=>{
    const {active, alignx, aligny} = props;
    const display = active ? 'block': 'none';

    const XAligns = {
      left: { left: 0 },
      right: { right: 0 },
      center: {
        left: "50%",
        transform: 'translateX(-50%)',
      },
      fill:{
        left:0,
        right:0,
      }
    }

    const YAligns = {
      top: { top: 0 },
      below: { top: "100%" },
      bottom: { bottom: 0 },
      above: { bottom: '100%' },
      middle: {
        top: '50%',
        transform: 'translateY(-50%)',
      }
    }

    return {
      display,
      ...XAligns[alignx || "left"],
      ...YAligns[aligny || "below"]
    };
  }}
`


/**
 * Toggle Label
 */

type ToggleLabelProps = {
  onClick?: React.MouseEventHandler,
  children?: React.ReactNode,
  className?: string,
}

const ToggleLabelBase = (props:ToggleLabelProps)=>{
  const { children, className, onClick } = props;
  return (
    <span className={className} onClick={onClick}>
      {children}
      <Icon>keyboard_arrow_down</Icon>
    </span>
  );
}

const ToggleLabel = styled(ToggleLabelBase)<ToggleLabelProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  ${Icon}{
    margin-left: 0.3em;
    opacity: 0.5;
    color: inherit;
  }
`

//====================
// Dropdown Component
//====================

export type DropdownRenderProps = {
  active: boolean,
  setActive: (active:boolean)=>void,
  icon?: string,
  label?: string
}

export type DropdownProps = HoverProps & ColorProps & SpaceProps &{
  icon?: string,
  label?: string,
  render?: (props:DropdownRenderProps)=>React.ReactNode,
  popupAlignX?: AlignX,
  popupAlignY?: AlignY,
  children?: React.ReactNode,
  className?: string,
}

const useOusideClick = (handler: ()=>void)=>{
  const ref = useRef<HTMLElement>(null);
  useEffect(
    () => {
      const handleClick = (e:any)=>{
        const { current } = ref;
        if(current && !current.contains(e.target)){
          handler()
        }
      }
      window.addEventListener('click', handleClick, false);
      return () => {
        window.removeEventListener('click', handleClick, false);
      }
    }
  );
  return ref;
}

function DropdownBase(props : DropdownProps){
  const { icon, label, render, children, popupAlignX, popupAlignY, className } = props;
  const [ active, setActive ] = useState(false);
  const popupRef = useOusideClick(()=>{ active && setActive(false)});
  const handleToggle = ()=> setActive(!active);
  return(
    <div className={className}>
      { render ? (
          render({active, setActive, icon, label})
        ):( label ? (
          <ToggleLabel
            onClick={handleToggle}>
            {label}
          </ToggleLabel>
        ):(
          <IconButton
            onClick={handleToggle}
            icon={icon}/>
        ))}

      {active && (
        <Popup
          ref={popupRef}
          alignx={popupAlignX}
          aligny={popupAlignY}
          active={active}
          onClick={(e)=>{
            setActive(false);
          }}>
          {children}
        </Popup>
      )}
    </div>
  )
}


/**
 * Dropdown Container
 */
const Dropdown = styled(DropdownBase)<HoverProps & ColorProps & SpaceProps>`
  position: relative;
  display: inline-block;
  z-index: auto;
  & > a {
    cursor: pointer;
    ${hover}
  }
  ${color}
  ${space}
`

Dropdown.defaultProps = {
  color: "inherit",
  hover: "primary"
}

export default Dropdown;
