import styled from 'styled-components';
import responsive from '../utils/stylefn/responsive';
import hoverShadow from '../utils/stylefn/hoverShadow';
import { ColorProps } from 'components/types/style-system';
import {
  space,
  SpaceProps,
  color,
  width,
  WidthProps,
  height,
  HeightProps,
  flex,
  FlexProps,
  order,
  OrderProps,
  alignSelf,
  AlignSelfProps,
  flexWrap,
  FlexWrapProps,
  flexDirection,
  FlexDirectionProps,
  alignItems,
  AlignItemsProps,
  justifyContent,
  JustifyContentProps,
  fontSize,
  FontSizeProps,
  borderRadius,
  BorderRadiusProps,
  boxShadow,
  BoxShadowProps,
  ResponsiveValue,
  opacity,
  OpacityProps,
  BackgroundImageProps,
  backgroundImage,
} from 'styled-system'

/**
 * View component
 */
export type ViewProps = SpaceProps & WidthProps & HeightProps & FontSizeProps & ColorProps & FlexProps & OrderProps & AlignSelfProps & BoxShadowProps & BorderRadiusProps & OpacityProps & BackgroundImageProps & {
  responsive?: ResponsiveValue<any>
}

export const View  = styled('div')<ViewProps>`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-size: cover;
  ${space}
  ${width}
  ${height}
  ${fontSize}
  ${color}
  ${opacity}
  ${flex}
  ${order}
  ${alignSelf}
  ${boxShadow}
  ${borderRadius}
  ${responsive}
  ${backgroundImage}
`



/**
 * Flex Component
 */
export const Flex = styled(View)<ViewProps & FlexWrapProps &  FlexDirectionProps &  AlignItemsProps & JustifyContentProps>`
  display: flex;
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${justifyContent}
`

/**
 * Paper Component
 */
export const Paper = styled(View)<ViewProps & { hoverShadow?: string }>`
  min-width: 100px;
  min-height: 100px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover{
    ${hoverShadow}
  }
`

Paper.defaultProps={
  bg: 'white',
  boxShadow: 'd1',
}
