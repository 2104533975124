import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="tungsten" title="Nickel Alloys"/>
      <Section>
        <p>
          Nickel is a versatile element that will blend with most alloys and metals such as molybdenum, chromium, iron, and copper. Nickel alloys are alloys with nickel as principal element. Because of its high versatility, together with the outstanding heat and corrosion resistance to seawater, has led to the use in a diverse range of applications and commercials, such as:
        </p>
        <ul>
          <li>Aircraft gas turbines</li>
          <li>Steam turbines in power plants</li>
          <li>Extensive use in the energy and nuclear power markets</li>
          <li>Pharmaceuticals and medical equipment</li>
        </ul>
        <p>
          Nickel alloys provide excellent oxidation resistance at high temperatures while maintaining quality weldability, workability, and ductility. Besides, it is also used substantially worldwide because of its corrosion resistance, special magnetic, and thermal expansion properties. Therefore, industries such as energy, power, chemical, and petrochemical rely on nickel alloys in critical applications.
        </p>
      </Section>
    </Article>
  )
}
