import ReactGA from 'react-ga';

const ga = (function(trackingId){
  const testMode = process.env.NODE_ENV === 'test';
  ReactGA.initialize(trackingId,  {testMode});
  return {
    pageView(location){
      ReactGA.pageview([location.pathname, location.search].join());
    },

    event(category, action, data={}){
      ReactGA.event({category, action, ...data});
    },
  }
})('UA-144589859-1')

export default ga;
