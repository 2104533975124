import React from 'react';
import styled, { css } from 'styled-components';
import { space, SpaceProps, color } from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import Icon from '../icon';

export type ChipProps = ColorProps & SpaceProps & {
  icon? : string,
  children? : React.ReactNode,
  size?: "s" | "m" | "l",
}

function ChipBase(props:ChipProps & {className?: string}){
  const { icon, children, className} = props;
  return (
    <span className={className}>
      {icon && <Icon>icon</Icon>}
      {children}
    </span>
  );
}

const Chip = styled(ChipBase)<ChipProps>`
  display: inline-block;
  line-height: 2rem;
  height: 2rem;
  font-size: 1rem;
  border-radius: 1rem;
  padding: 0 1rem;
  ${color}
  ${space}
  ${(props)=>{
    let {size} = props;
    if(size === 's'){
      return css`
        font-size: 0.8rem;
        line-height:1.2rem;
        height: 1.2rem;
        border-radius: 0.5rem;
        padding: 0 0.5rem;
      `
    }
  }}
`

Chip.defaultProps = {
  bg: "gray2",
  color: "black",
}

export default Chip;
