import React, { useState } from 'react';
import styled from 'styled-components';
import { color } from 'styled-system';
import { withRouter, RouteComponentProps } from 'react-router';
import AppDrawer from './AppDrawer';
import AppBar,{ AppBarRow } from 'components/appBar';
import { View, IconButton } from 'components';
import { ColorProps } from 'components/types/style-system'
import { Menu, MenuLink } from './Menu';

/**
 * RootContainer
 */
const RootContainer = styled('div')<ColorProps>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & > .content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 0;
    -webkit-overflow-scrolling: touch;
  }
  ${color}
`

/**
 * Page Root
 */
type NavPageProps = ColorProps & {
  appbarActive?: boolean,
  title?: string,
  children?: React.ReactNode
}

const styles = {
  hide:{
    display: 'none',
  },
  show:{
    display: 'block'
  }
}

export function NavPage(props: NavPageProps){
  const { title, appbarActive, ...containerProps} = props;
  const [toggle, setToggle] = useState(false);

  const appBarProps = appbarActive ? {
    bg: "gray1",
    boxShadow: "d2"
  }:{
    bg:'gray1',
    color: '#666'
  }

  return (
    <RootContainer {...containerProps}>
      <div className="content">
        {props.children}
      </div>
      <AppBar {...appBarProps}>
        <AppBarRow height={56}>

          <View responsive={[styles.show, styles.hide]}>
            <IconButton icon="menu" onClick={(e)=>setToggle(!toggle)}/>
          </View>

          <View  ml={["0.5rem","1rem","2rem"]}>
            <Logo src="/assets/logo1.png"/>
          </View>

          <View flex={1} mr={[0,0,"1rem"]} responsive={[styles.hide, styles.show]}>
            <Menu>
              <MenuLink exact to="/">Home</MenuLink>
              <MenuLink to="/products">Products</MenuLink>
              <MenuLink to="/services">Services</MenuLink>
              <MenuLink to="/contacts">Contact us</MenuLink>
            </Menu>
          </View>

        </AppBarRow>
      </AppBar>

      <AppDrawer toggle={toggle} setToggle={setToggle}/>
    </RootContainer>
  );
}

const Logo = styled.img`
  margin-top: -7px;
  height: 30px;
`

/**
 * Article Page
 * @param props
 */

type PageProps = ColorProps & {
  title: string,
  children?: React.ReactNode
}

const BackButton = withRouter((props:RouteComponentProps)=>{
  const {history} = props;
  return <IconButton mx={2} icon="arrow_back" onClick={(e)=>history.goBack()}/>
})

export function Page(props: PageProps){
  const { title, ...containerProps } = props;
  return (
    <RootContainer {...containerProps}>
      <div className="content">
        {props.children}
      </div>
      <AppBar boxShadow="d1">
        <AppBarRow height={56}>
          <BackButton/>
          <h1>{title}</h1>
        </AppBarRow>
      </AppBar>
    </RootContainer>
  );
}
