import React, {useState} from 'react';
import Parallax, { ParallaxLayer } from 'components/parallax';
import { NavPage } from '../common/Pages';
import Footer from '../common/Footer';

import Screen01 from './Screen01';
import Screen02, {Content as S02Content } from './Screen02';
import Screen03, { Content as S03Content } from './Screen03';
import Screen04, { Content as S04Content } from './Screen04';
import Screen05, { Content as S05Content } from './Screen05';
import Screen06, { Content as S06Content } from './Screen06';
import Screen07, { Content as S07Content } from './Screen07';



export default function Landing(){
  const [appbarActive, setAppbarActive] = useState(false);

  const handlePageScroll = (page:number)=>{
    setAppbarActive(page > 1.1);
  }

  return (
    <NavPage appbarActive={appbarActive}>
      <Parallax pages={7.5} extra={<Footer/>} onPageScroll={handlePageScroll}>
        <ParallaxLayer offset={0} zIndex={100} children={<Screen01/>}/>

        <ParallaxLayer offset={0} zIndex={90} sticky={1} render={(pos)=>(<Screen02 pos={pos}/>)}/>
        <ParallaxLayer offset={0} zIndex={91} sticky={1} render={(pos)=>(<S02Content pos={pos}/>)}/>

        <ParallaxLayer offset={2} zIndex={80} speed={0} render={()=>(<Screen03/>)}/>
        <ParallaxLayer offset={2} zIndex={85} speed={-0.2} sync={false} render={(pos)=>(<S03Content pos={pos}/>)}/>

        <ParallaxLayer offset={3} zIndex={70} speed={-0.5} render={()=>(<Screen04/>)}/>
        <ParallaxLayer offset={3} zIndex={75} speed={0} render={()=>(<S04Content/>)}/>

        <ParallaxLayer offset={4} zIndex={60} speed={-0.5} render={()=>(<Screen05/>)}/>
        <ParallaxLayer offset={4} zIndex={61} speed={0} render={(pos)=>(<S05Content pos={pos}/>)}/>

        <ParallaxLayer offset={5} zIndex={50} speed={-0.5} render={()=>(<Screen06/>)}/>
        <ParallaxLayer offset={5} zIndex={53} speed={0} render={()=>(<S06Content/>)}/>

        <ParallaxLayer offset={6} zIndex={50} sticky={0.5} render={(pos)=>(<Screen07 pos={pos}/>)}/>
        <ParallaxLayer offset={6} zIndex={53} render={(pos)=>(<S07Content pos={pos}/>)}/>
      </Parallax>
    </NavPage>
  );
}
