import React  from 'react';
import { Icon, Flex } from 'components';
import { Screen, H1 } from '../common/components';
import styled from 'styled-components';

export default function Screen01(){
  return (
    <Container>
      <Flex flex={1} backgroundImage="url(/assets/homebg01.png)" color="white">
        <Flex flexDirection="column" width={['90%', '90%', '90%', '60%']} mx="auto" height="100%" justifyContent="center">
          <H1>
            When it comes to steel <b>trading</b> and project <b>management</b> <em>RedMint Steel</em> is here to help
          </H1>
          <IconWrapper>
            <Icon>arrow_downward</Icon>
          </IconWrapper>
        </Flex>
      </Flex>
    </Container>
  )
}

const Container = styled(Screen)`
  display: flex;
`

Container.defaultProps = {
  boxShadow: "d3",
  bg: 'gray1',
  p: ["0", "56px 1rem 1rem 1rem", "56px 2rem 2rem 2rem"]
}


const IconWrapper = styled.div`
  margin: 2rem auto;
  padding: 1rem;
  border: 2px solid #FFF;
  border-radius: 50%;
  & > ${Icon}{
    font-size: 2rem;
    line-height: 1;
  }
`
