import React from 'react';
import map from 'lodash/map';
import styled from 'styled-components';
import { width, space, color } from 'styled-system'

function Table(props){
  const { className, columns, data, rowDisabled } = props;
  return (
    <table className={className}>
      <Header columns={columns}/>
      <tbody>
        {map(data, (row, index)=>{
          const disabled = rowDisabled && rowDisabled(row);
          return (
            <Row
              key={index}
              disabled={disabled}
              columns={columns}
              data={row}
              />
          );
        })}
      </tbody>
    </table>
  );
}

function Row(props){
  const { data, disabled, columns } = props;
  const dataRenderer = (key)=>(data)=>data[key];
  return (
    <TR disabled={disabled}>
      {map(columns, (column, index)=>{
        const { key } = column;
        const renderCell = column.renderCell || dataRenderer(key);
        return (
          <td key={key}>
            {renderCell(data)}
          </td>
        );
      })}
    </TR>
  );
}

const TR = styled.tr`
  ${({disabled})=>{
    return disabled ? {opacity: 0.7} : {opacity: 1}
  }}
`

function Header(props){
  const { columns } = props;
  return (
    <thead>
      <tr>
        {map(columns, (column, index)=>{
          const {key, label, width} = column
          const extra = {width}
          return (
            <th key={key} {...extra}>
              {label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default styled(Table)`
  border-spacing: 0;

  th, td{
    font-size: 0.9rem;
    text-align: left;
    line-height:1.5;
    padding: 8px 2px 8px 24px;
  }

  th{
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
  }

  td{
    font-weight: 400;
    border-bottom: 1px solid #e9e9e9;
  }

  tbody > tr{
    &:hover{
      background-color:#f5f5f8;
    }
  }

  ${width}
  ${space}
  ${color}
`
