const colors = {
  base: "#07c",
  white: "#FFF",
  black: "#374047",

  gray0: "#f8f9f9",
  gray1: "#ebedee",
  gray2: "#dee1e3",
  gray3: "#cfd3d6",
  gray4: "#bec4c8",
  gray5: "#acb4b9",
  gray6: "#97a1a7",
  gray7: "#7f8a93",
  gray8: "#5f6e78",
  gray9: "#374047",
  gray10: "#212b2b",
  gray11: "#161d1c",

  blue0: "#e4f0f9",
  blue1: "#c6e1f3",
  blue2: "#a5cfed",
  blue3: "#7db9e5",
  blue4: "#4a9eda",
  blue5: "#0077cc",
  blue6: "#006bb7",
  blue7: "#005da0",
  blue8: "#004d84",
  blue9: "#00365d",

  indigo0: "#eaebfa",
  indigo1: "#d2d5f6",
  indigo2: "#b7bbf0",
  indigo3: "#959ce9",
  indigo4: "#6872e0",
  indigo5: "#0011cc",
  indigo6: "#000fb7",
  indigo7: "#000da0",
  indigo8: "#000a83",
  indigo9: "#00075c",

  violet0: "#f0e9fa",
  violet1: "#e1d2f6",
  violet2: "#ceb6f0",
  violet3: "#b894e9",
  violet4: "#9966e0",
  violet5: "#5500cc",
  violet6: "#4c00b8",
  violet7: "#4300a1",
  violet8: "#370084",
  violet9: "#27005e",

  fuschia0: "#f9e9fa",
  fuschia1: "#f2d1f5",
  fuschia2: "#ebb5f0",
  fuschia3: "#e293e9",
  fuschia4: "#d665e0",
  fuschia5: "#bb00cc",
  fuschia6: "#a900b8",
  fuschia7: "#9400a2",
  fuschia8: "#7b0086",
  fuschia9: "#580061",

  pink0: "#fae9f3",
  pink1: "#f5d1e6",
  pink2: "#f0b6d8",
  pink3: "#e994c6",
  pink4: "#e066ad",
  pink5: "#cc0077",
  pink6: "#b8006b",
  pink7: "#a2005e",
  pink8: "#86004e",
  pink9: "#610038",

  red0: "#faeaeb",
  red1: "#f6d2d5",
  red2: "#f0b7bc",
  red3: "#ea969d",
  red4: "#e16973",
  red5: "#cc0011",
  red6: "#b8000f",
  red7: "#a2000d",
  red8: "#86000b",
  red9: "#610008",

  orange0: "#f9ede4",
  orange1: "#f3d9c6",
  orange2: "#ecc2a4",
  orange3: "#e4a87c",
  orange4: "#da864a",
  orange5: "#cc5500",
  orange6: "#b84c00",
  orange7: "#a04300",
  orange8: "#843700",
  orange9: "#5e2700",

  yellow0: "#f8f6de",
  yellow1: "#f1ecba",
  yellow2: "#e9e293",
  yellow3: "#e0d668",
  yellow4: "#d7c938",
  yellow5: "#ccbb00",
  yellow6: "#b8a900",
  yellow7: "#a29400",
  yellow8: "#867b00",
  yellow9: "#615800",

  lime0: "#eef8df",
  lime1: "#dcf1bd",
  lime2: "#c7ea97",
  lime3: "#b1e16c",
  lime4: "#96d73b",
  lime5: "#77cc00",
  lime6: "#6bb800",
  lime7: "#5ea200",
  lime8: "#4e8600",
  lime9: "#386100",

  green0: "#e5f9e4",
  green1: "#c9f3c6",
  green2: "#a9eca3",
  green3: "#84e47b",
  green4: "#54da48",
  green5: "#11cc00",
  green6: "#0fb800",
  green7: "#0da200",
  green8: "#0b8600",
  green9: "#086100",

  teal0: "#e3f9ec",
  teal1: "#c5f3d8",
  teal2: "#a2ecc1",
  teal3: "#79e4a6",
  teal4: "#46da84",
  teal5: "#00cc55",
  teal6: "#00b84c",
  teal7: "#00a243",
  teal8: "#008638",
  teal9: "#006128",

  cyan0: "#e3f9f7",
  cyan1: "#c4f3ef",
  cyan2: "#a0ece5",
  cyan3: "#77e3da",
  cyan4: "#44d9cd",
  cyan5: "#00ccbb",
  cyan6: "#00b8a9",
  cyan7: "#00a294",
  cyan8: "#00867b",
  cyan9: "#006159"
}

export default {
  ...colors,
  primary: colors.blue5,
  onPrimary: colors.white,
  secondary: "#03DAC6",
  onSecondary: colors.balck,
  error: "#B00020",
  onError: colors.white,
  background: colors.gray0,
  onBackground: colors.balck,
  surface: colors.white,
  onSurface: colors.balck,
  mint: '#009955',
  steel: '#AABBBB',
  tungsten: "#424242",
}
