import React from 'react';
import styled from 'styled-components';
import { color, space, SpaceProps } from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import Drawer, { DrawerProps } from './Drawer';
import ToggleMask from './ToggleMask'


/**
 * Divider
 */
export const Divider = styled('hr')<SpaceProps & ColorProps>`
  display: block;
  border-width:0;
  min-height: 1px;
  width: 100%;
  ${color}
  ${space}
`

Divider.defaultProps = {
  bg: 'gray1',
  mt: 2,
  mb: 2,
}

/**
 * Title
 */
export const Title = styled('h6')<ColorProps>`
  font-size: 1.4rem;
  font-weight: 500;
  color: inherit;
  margin: 1rem 0;
  margin-left: 1.5rem;
  ${color}
`

/**
 * Label
 */
export const Label = styled('label')<ColorProps>`
  font-size: 1rem;
  font-weight: 300;
  color: inherit;
  margin: 1rem 0;
  margin-left:1.5rem;
  ${color}
`

/**
 * Navigation Drawer
 */
export type NavigationDrawerProps = DrawerProps & {
  setToggle: (toggle:boolean)=>void,
  children?: React.ReactNode,
}

function NavigationDrawer(props : NavigationDrawerProps){
  const { toggle, setToggle, children, ...drawerProps } = props;
  return (
    <React.Fragment>
      <Drawer toggle={toggle} {...drawerProps}>
        {children}
      </Drawer>

      <ToggleMask
        toggle={toggle}
        onClick={()=>setToggle(false)}/>
    </React.Fragment>
  )
}

export default NavigationDrawer
