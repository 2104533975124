import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { Route } from 'react-router';

export const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex:1;
  list-style: none;
  overflow: hidden;
  margin: 0 1rem;
`

type MenuItemProps = {
  href?: string,
  selected?: boolean,
  onClick?: MouseEventHandler,
  children?: React.ReactNode,
}

export const MenuItem = styled.li<MenuItemProps>`
  margin: 0 1rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  cursor: pointer;
  color: #AAA;
  ${(props)=>props.selected && css`
    color: #333;
  `}
`

type MenuLinkProps = {
  to: string,
  exact?: boolean,
} & MenuItemProps

export const MenuLink = (props:MenuLinkProps)=>{
  const {to, exact, ...menuItemProps} = props;
  return (
    <Route
      path={to}
      exact={exact}
      children={({match, history})=>(
        <MenuItem
          selected={!!match}
          onClick={()=>history.push(to)}
          href={to}
          {...menuItemProps}/>
      )}/>
  );
}
