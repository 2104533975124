import { space, color, boxShadow, lineHeight, height, SpaceProps, BoxShadowProps, HeightProps, LineHeightProps } from 'styled-system';
import styled from 'styled-components';
import { ColorProps } from 'components/types/style-system';

//---------------
// AppBarRow
//---------------
export type AppBarRowProps = HeightProps & LineHeightProps;

export const AppBarRow = styled('section')<AppBarRowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  h1, h2, h3, h4, h5 {
    font-weight: 400;
    font-size: 1.1rem;
    letter-spacing: 0.06rem;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    align-self: center;
    line-height: inherit;
  }
  ${height}
  ${lineHeight}
`

//---------------
// AppBar
//---------------
export type AppBarProps = SpaceProps & ColorProps & BoxShadowProps;

const AppBar = styled('header')<AppBarProps>`
  position: absolute;
  top:0;
  left: 0;
  right:0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  ${color}
  ${space}
  ${boxShadow}
`

AppBar.defaultProps = {
  bg: "white",
  color: 'black'
}

export default AppBar

