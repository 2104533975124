import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { color, space, SpaceProps } from 'styled-system';
import { ColorProps } from 'components/types/style-system';

export type CheckBoxVariants = 'radio' | 'checkbox' | 'switch';

export type CheckboxProps = SpaceProps & ColorProps & {
  checked? : boolean,
  label?: string,
  disabled?: boolean,
  onClick?: MouseEventHandler,
  variant?: CheckBoxVariants,
}

function CheckboxBase(props: CheckboxProps & {className?: string}){
  const {checked, label, variant, disabled, className, onClick} = props;
  const handleClick=(e:React.MouseEvent<HTMLDivElement>)=>{
    disabled && e.stopPropagation();
  }

  return (
    <section className={className} onClick={onClick}>
      <div className='wrapper' onClick={handleClick}>
        <span className={variant}>
          <input
            type="checkbox"
            onChange={()=>{}}
            checked={checked}
            disabled={disabled}/>
        </span>
        {label && (<label>{label}</label>)}
      </div>
    </section>
  );
}

const Checkbox = styled(CheckboxBase)<CheckboxProps>`
  font-size: 1rem;
  color: #999;
  ${color}
  ${space}

  & > .wrapper{
    display: inline-block;
    user-select: none;
    cursor: pointer;

    & > *{
      line-height: 2rem;
      vertical-align: middle;
    }

    label{
      margin-left: 1rem;
      cursor: inherit;
    }

    input:focus{
      outline: 1px dotted #CCC;
      outline-offset: 2px;
    }

    & > span.checkbox, & > span.radio {
      display: inline-block;
      input[type=checkbox]{
        -webkit-appearance: none;
        display: inline-block;
        position: relative;
        line-height: inherit;
        color: inherit;
        &:before{
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 1.1rem;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'liga';
          color: inherit;
          user-select: none;
          content:"";
        }
      }
    }

    & > span.checkbox > input[type=checkbox]{
      &:before{
        content:"check_box_outline_blank"
      }
      &:checked{
        &:before{
          content:"check_box"
        }
      }
    }

    & > span.radio > input[type=checkbox]{
      &:before{
        content:"radio_button_unchecked"
      }
      &:checked{
        &:before{
          content:"radio_button_checked"
        }
      }
    }

    & > span.switch {
      position: relative;
      display: inline-block;
      width: 2.5rem;
      height: 2rem;
      &:before, &:after{
        position: absolute;
        top:0;
        left:0;
        right:0;
        content:"";
        margin: 0.7rem 0.3rem;
        height: 0.6rem;
        border-radius: 0.3rem;
      }
      &:before{
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        z-index: 5;
      }
      &:after{
        background-color: rgba(255,255,255,.3);
        z-index: 6;
      }

      input[type=checkbox]{
        -webkit-appearance: none;
        display: inline-block;
        line-height: inherit;
        position: absolute;
        height:1.1rem;
        width:1.1rem;
        top: 0.4rem;
        left:0;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        border-radius: 50%;
        z-index: 10;
        &:checked{
          left: 1.4rem;
        }
      }

      ${({disabled, checked, theme})=>{
        const disabledStyle = disabled && css`
         &:before{
          background-color: #BBB;
         }
         input[type=checkbox], input[type=checkbox]:checked{
            background-color: #DDD;
          }
        `
        return css`
          &:before{
            background-color: ${checked?theme.colors.primary : theme.colors.black}
          }
          input[type=checkbox]{
            background-color: ${theme.colors.gray5};
            box-shadow: ${theme.shadows.d1};
            &:checked{
              background-color: ${theme.colors.primary};
            }
          }
          ${disabledStyle}
        `
      }}
    }

    ${({disabled, checked, theme})=>{
      const iconStyle= checked && !disabled && css`
        & > span {
          color: ${theme.colors.primary}
        }
      `
      const disabledStyle = disabled && css`
        cursor: default;
        color: ${theme.colors.gray5};
        &:hover {
          color: ${theme.colors.gray5};
        }
      `
      return css`
        color: rgba(0,0,0,.5);
        &:hover {
          color: ${theme.colors.black}
        }
        ${disabledStyle}
        ${iconStyle}
      `
    }}
  }
`

Checkbox.defaultProps = {
  checked: false,
  variant: 'checkbox'
}

export default Checkbox;
