import React from 'react';
import { NavPage } from '../common/Pages';
import Footer from '../common/Footer';

import { Article, Banner, Section } from 'pages/common/Article';
import ProductItem from './ProductItem';
import {Row} from 'components/grid'

import { products, materials } from './data';
import { Flex } from 'components';
import { H1 } from 'pages/common/components';

export default function ProductsIndex(){
  return (
    <NavPage>
      <Article>
        <Banner
          height="800px"
          backgroundImage="url(/assets/ps02_bg.jpg)">
          <Flex height="100%" alignItems="center" justifyContent="center">
            <Section>
              <H1>
                Products and Materials
              </H1>
            </Section>
          </Flex>
        </Banner>
        <Section>
          <h3> Products </h3>
          <Row>
            {products.map(product=>(
              <ProductItem
                key={product.id}
                to={`/products/${product.id}`}
                product={product} span={1}/>
            ))}
          </Row>
        </Section>

        <Section>
          <h3> Materials </h3>
          <Row>
            {materials.map(product=>(
              <ProductItem
                key={product.id}
                to={`/products/${product.id}`}
                product={product} span={1}/>
            ))}
          </Row>
        </Section>
      </Article>
      <Footer/>
    </NavPage>
  );
}
