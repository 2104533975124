import React from 'react';
import { View, Flex, InputField, Button } from 'components';
import { NavPage } from '../common/Pages';
import { Article, Section } from 'pages/common/Article';
import { H1 } from 'pages/common/components';
import { createCell } from 'components/grid';
import { WidthProps, SpaceProps, width, space } from 'styled-system';
import styled from 'styled-components';
import {AddressLine} from 'pages/common/Footer';


const mapToken="AIzaSyB05fAZlB0FoFJ700KY0aa2hNjKlxwKE0A"
const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=Sandy+Bay,Tasmania,Australia&zoom=13&size=400x400&maptype=roadmap
&markers=color:blue%7Clabel:P%7C-42.890888, 147.327153&key=${mapToken}`

export default function Products(){
  return (
    <NavPage appbarActive={false} bg="gray1">
      <Backdrop/>
      <Article style={{zIndex:10}} color="white">
        <Section color="white">
          <H1 my={3}>
            Get in <b>touch</b> with us
          </H1>
        </Section>

        <Row mb={2}>
          <Cell span={2} my={2}>
            <Map/>
          </Cell>
          <Cell span={2} my={2}>
            <AddressLine/>
            <ContactForm/>
          </Cell>
        </Row>
      </Article>
    </NavPage>
  );
}



/**
 * Backdrop
 */
const Backdrop = styled.div`
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-image: url(/assets/hobart.jpg);
  background-size: cover;
  z-index: -1;
`

/**
 * Grid
 */
const Cell = createCell(View);

const Row = styled('div')<SpaceProps & WidthProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
  background-color: rgba(255,255,255,.95);
  color: #333;
  border-radius: 10px;
  margin: 0 auto;
  ${space}
  ${width}
`

Row.defaultProps = {
  width: ['90%', '90%', '960px']
}

/**
 * Map
 */
const Map = styled.div`
  width:100%;
  height: 100%;
  min-height: 300px;
  background-image: ${`url(${mapUrl})`};
  background-size: cover;
  background-position: 0.5, 0.5;
  border: 5px solid #FFF;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`

/**
 * Logo
 */
const Logo = styled.img`
  height: 30px;
  margin-bottom: 1rem;
`

Logo.defaultProps={
  src:"/assets/logo1.png"
}


/**
 * ContactForm
 */
const ContactForm = ()=>(
  <Flex flexDirection="column" mt={2}>
    <InputField
      mb={3}
      variant="filled"
      label="How to contact"
      helper="Your email or mobile"/>

    <InputField
      mb={3}
      variant="filled"
      type="textarea"
      rows={4}
      label="How can we help"
      helper="Let us know how can we help"/>

    <Button
      type="contained"
      variant="primary">
      Send
    </Button>
  </Flex>
)
