import React from 'react';
import styled from 'styled-components';
import { space, color, width, boxShadow, fontSize, FontSizeProps, BoxShadowProps, WidthProps, SpaceProps } from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import Icon from '../icon';
import hover, { HoverProps } from '../utils/stylefn/hover';
import linkable from '../utils/hocs/linkable';

//-----------------------
// Menu item
//-----------------------
type MenuItemProps = {
  icon?: string,
  command?: string,
  children: React.ReactNode,
  onClick?: React.MouseEventHandler,
}

const MenuItemBase = (props: MenuItemProps & {className?: string})=>{
  const { children, icon, command, className, onClick} = props;
  return (
    <li className={className} onClick={onClick}>
      <section style={{flex:1}}>
        {icon && <Icon>{icon}</Icon>}
        {children}
      </section>
      <section style={{alignSelf: 'flex-end'}}>
        {command && <span>{command}</span>}
      </section>
    </li>
  );
}

export const MenuItem = styled(MenuItemBase)<MenuItemProps & HoverProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 2.5em;
  font-size: 0.8em;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;

  & > section:first-child{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 60px;
    ${Icon}{
      margin-right: 0.8rem;
      font-size: 1.2rem;
    }
  }

  & > section:last-child{
    align-self: flex-end;
    margin-left: 1rem;
    opacity: 0.5;
  }
  ${hover}
`

MenuItem.defaultProps = {
  hover: {bg:'gray1'}
}

//-----------------------
// MenuLik
//-----------------------
export const MenuLink = linkable(MenuItem);

//-----------------------
// MenuDivider
//-----------------------
export const MenuDivider = styled('li')<ColorProps>`
  border: none;
  height: 1px;
  margin: 0.5rem 0;
  ${color}
`

MenuDivider.defaultProps = {
  bg: "gray2"
}

//-----------------------
// Menu
//-----------------------
const Menu = styled('ul')<FontSizeProps & BoxShadowProps & ColorProps & WidthProps & SpaceProps>`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  list-style: none;
  margin:0;
  ${fontSize}
  ${boxShadow}
  ${color}
  ${width}
  ${space}
`

export default Menu;
