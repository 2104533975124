import React from 'react';
import styled from 'styled-components';
import { View, Flex, LinkButton } from 'components';
import interpolation from 'components/utils/interpolation';
import { Screen, ContentRoot, PosProps, H1, Subtitle } from '../common/components';

export default function Screen05(){
  return (
    <Screen backgroundImage='url(/assets/s05_bg.jpg)'/>
  );
}

export function Content(props:PosProps){
  return (
    <ContentRoot color="white" opacity={interpolation([[0,1],[1,0]])(props.pos)}>
      <H1>
        Contract &amp; Project Management
      </H1>
      <Subtitle>
        Beside assisting customers in outsourcing and trading steel products, RedMint Steel also provides consulting and services in contract
        and project management. As a one-stop business solution, we have a comprehensive range of services to manage customers’ contracts and
        projects from supply chain to the end-users.
      </Subtitle>
      <View mt={4}>
        <LinkButton variant="primary" to="/services" type="contained" > More about our services </LinkButton>
      </View>
    </ContentRoot>
  );
}








export function Contract(props:PosProps){
  return (
    <ContentRoot opacity={interpolation([[0.5,1],[1,0]])(props.pos)}>
      <Card>
        <View as="aside" bg="cyan5" color="white">
          <H4>Contract Management</H4>
        </View>
        <UL>
          <li> Monitorying cargo loading process</li>
          <li> Finalising and outsourcing vessel spaces or container bookings to arrange shipment</li>
          <li> Liaising with overseas and local freight services for customs clearance</li>
          <li> Delivery arrangement with local transport</li>
          <li> Ensure cargoes or products arrive on-time at the customers’ sides</li>
        </UL>
      </Card>
    </ContentRoot>
  );
}

export function Project(props:PosProps){
  return (
    <ContentRoot opacity={interpolation([[0.5,1],[1,0]])(props.pos)}>
      <Card>
        <View as="aside" bg="blue5" color="gray1">
          <H4>Project Management</H4>
          <p>
            Project management could also involve procurement process if customers appoint us to manage certain project from the beginning.
            This means our team will liaise closely with the mills, factories, suppliers, vendors, contractors, logistics companies, shipping line companies, customers project engineering sides, and end-users.
          </p>
        </View>
        <UL>
          <li>Project analysis</li>
          <li>Requisition and procurement</li>
          <li>Kick-off meeting arrangement at the mills</li>
          <li>Operations and monitoring cargoes readiness from production</li>
          <li>Inspection and testing if required and applicable</li>
          <li>Shipping, customs clearance and logistics arrangement</li>
          <li>Traceability list includes production and cargoes</li>
        </UL>
      </Card>
    </ContentRoot>
  );
}

export function Quality(props:PosProps){
  return (
    <ContentRoot opacity={interpolation([[0,1],[1,0]])(props.pos)}>
      <Card>
        <View as="aside" bg="tungsten" color="steel">
          <H4>Quality and Testing</H4>
          <p>
            On top of consulting and services like contract and project management, we also carry out and prioritise quality checking as well as running through several tests to ensure products is fulfilled with customers and projects’ requirement.
          </p>
        </View>
        <UL>
          <li>Product analysis</li>
          <li>Hardness tests</li>
          <li>Bend or ductility tests</li>
          <li>Impact tests: charpy impact test and izod impact test</li>
          <li>Tensile tests: Z test, longitudinal test, weld tensile test, and tension test</li>
        </UL>
      </Card>
    </ContentRoot>
  );
}


const H4 = styled(H1)`
  margin: 1rem 0;
  font-weight: 300;
`

H4.defaultProps={
  fontSize: ["2rem", "2rem", "3rem"],
  as: 'h4'
}


const UL = styled.ul`
  list-style: none;
  line-height: 1.2;
  color: #666;
  font-size: 1.2rem;
  font-weight:200;
  li{
    margin: 1rem 0;
  }
`


const Card = styled(Flex)`
  display: flex;
  background-color: #FFF;
  font-weight: 300;

  &>aside, &>ul{
    flex: 0.5;
    padding: 1.5rem;
  }

  aside {
    display:flex;
    flex-direction: column;
    justify-content: center;
  }

  p{
    margin: 0.5rem 0;
  }
`


Card.defaultProps={
  width: ['80%'],
  flexDirection:['column', 'row'],
  boxShadow: "d3",
}
