import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="tungsten" title="Stainless Steels"/>
      <Section>
        <p>
          Stainless steels are alloys made of iron with a minimum of 10.5% Chromium, the percentage is varied and approximately can reach to the maximum of 29%. Chromium produces a thin layer of oxide on surface of the steels, this will prevent any corrosions of the surface. The increase amount of Chromium provides an increasing of resistance to corrosion.
        </p>
        <p>
          Stainless steels can be identified and segregated into four categories according to their predominant crystalline structure:
        </p>
        <ul>
          <li>Austenitic is the most commonly used stainless steel accounting for more than 70% of production.</li>
          <li>Ferritic is steel (iron and a small addition of carbon) with at least 11% chromium added to produce the passive chromium oxide film. These alloys have similar properties to carbon steels when they are bent or cut or process and strongly attracted to a magnet.</li>
          <li>Martensitic was the first stainless steel developed the application of cutlery and has relatively high carbon content compared to other stainless steel types. Martensitic can be hardened by heat treatment and therefore high strength and hardness levels can be achieved.</li>
          <li>Duplex is stainless steel containing relatively high chromium and moderate amounts of nickel. The insufficient nickel content causes inadequate to generate a fully austenitic structure, therefore duplex is also the combination of ferritic and austenitic structures.</li>
        </ul>

        <p>
          The applications of stainless steels are commonly in chemical plant and equipment, architectural applications, food processing equipment, kitchen sinks, hot water tanks, surgical instruments, knife blades, and automotive equipment.
        </p>
        <p>
          The benefits of using stainless steels are corrosion resistance, high ductility and strength, high and low temperature resistance, excellent weldability, the least expensive choice in a life cycle cost benefit, aesthetic appeal and surface finish, and hygienic properties for application.
        </p>

      </Section>
    </Article>
  )
}
