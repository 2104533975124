import styled, { css } from 'styled-components';
import { space, color, fontSize, lineHeight, SpaceProps, FontSizeProps, LineHeightProps } from 'styled-system';
import { ColorProps } from 'components/types/style-system';

type IconProps = SpaceProps & FontSizeProps & LineHeightProps & SpaceProps & ColorProps & {
  variant?: 'outlined' | 'sharp',
  size?: 'xs' | 's' | 'm' | 'l',
  onClick?: Function,
}

const FONTS = {
  outlined: 'Material Icons Outlined',
  sharp: 'Material Icons Sharp',
}

const SIZES = {
  xs: '1rem',
  s:  '1.5rem',
  m:  '3rem',
  l:  '5rem',
}

export default styled('span')<IconProps>`
  display: inline-block;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  color: inherit;
  user-select: none;
  ${space}
  ${color}
  ${lineHeight}
  ${(props)=>{
    const {variant, size, onClick} = props;
    const font = variant && css`
      font-family: '${FONTS[variant]}';
    `
    const cursor= !!onClick && css`
      cursor: pointer;
    `
    return css`
      font-size: ${SIZES[size || 's']}
      ${font}
      ${cursor}
    `
  }}
  ${fontSize}
`
