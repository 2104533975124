import React from 'react';
import { View } from 'components';
import { Screen, ContentRoot, PosProps, H1, Subtitle } from '../common/components';
import interpolation from 'components/utils/interpolation';

export default function Screen03(){
  return (
    <Screen backgroundImage='url(/assets/s03_bg.jpg)'>

    </Screen>
  )
}

export function Content(props: PosProps){
  const opacity = interpolation([[-1,0],[-0.7, 0.3], [-0.5,1], [0,1], [1.2,0]])(props.pos);
  return (
    <ContentRoot>
      <H1>Vision &amp; Mission</H1>
      <View bg="rgba(255,255,255,.7)" p={4} opacity={opacity}>
        <Subtitle color="black" >
          <p>
            RedMint Steel’s <b>mission</b> is to deliver tailored-solutions that are efficient, cost-effective, safe and environmentally friendly.
          </p>
          <p>
            Its <b>vision</b> is to be a one-stop business solution that provides exceptional services to each customer, regardless of project or organisational size or its importance.
          </p>
        </Subtitle>
      </View>
    </ContentRoot>
  );
}

