import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon/Icon';
import IconButton, { IconButtonProps } from './IconButton'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const Container = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  ${Icon}{
    position: absolute;
    left:0;
    top:0;
  }

  &.in{
    .switch-enter{
      transform: rotate(-90deg);
      &.switch-enter-active{
         transform: rotate(0);
      }
    }
    .switch-exit{
      transform: rotate(0);
      &.switch-exit-active{
        transform: rotate(90deg);
      }
    }
  }

  &.out{
    .switch-enter{
      transform: rotate(90deg);
      &.switch-enter-active{
         transform: rotate(0);
      }
    }
    .switch-exit{
      transform: rotate(0);
      &.switch-exit-active{
        transform: rotate(-90deg);
      }
    }
  }

  .switch-enter{
    opacity: 0.01;
    &.switch-enter-active{
      opacity: 1;
      transition: all 200ms ease-in;
    }
  }

  .switch-leave{
    opacity: 1;
    &.switch-leave-active{
      opacity: 0.01;
      transition: all 100ms ease-in;
    }
  }
`

type AnimatedIconButtonProps = IconButtonProps & {
  transition?: string,
}

function AnimatedIconButton(props:AnimatedIconButtonProps){
  const { transition, icon, ...rest } = props;
  return (
    <IconButton {...rest}>
      <Container className={transition || 'in'}>
        <TransitionGroup>
          <CSSTransition key={icon} classNames="switch" timeout={{enter:200, exit:100}}>
            <Icon>{icon}</Icon>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    </IconButton>
  );
}

export default AnimatedIconButton;
