import React, { MouseEventHandler } from "react";
import { withRouter } from "react-router";

export type LinkableProps = {
  to: string
  onClick: MouseEventHandler
}

type TargetProps = LinkableProps & any

function linkable(Target : React.ComponentType<TargetProps>):React.ComponentType<any>{
  return withRouter((props: TargetProps & {history:any} )=>{
    const {history, to, ...baseProps} = props;
    return (
      <Target
        onClick={()=>history.push(to)}
        href={to}
        {...baseProps}/>
    );
  });
}

export default linkable;
