import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="tungsten" title="Copper Nickel"/>
      <Section>
        <p>Copper nickel is one of the alloys type that has high resistance to seawater environments. It is widely used for marine applications due to their excellent resistance to seawater corrosion, low macro-fouling rates, and good fabrication ability. Copper nickel has superior corrosion resistance in seawater. This shows that nickel to copper could improve copper nickel alloy’s strength, durability, and resistance to corrosion and erosion in natural waters, such as seawater and treated water.</p>
        <p>Copper nickel is an ideal material for applications of naval and commercial shipping, desalination plants, sea water and hydraulic pipelines, oil rigs and platforms, fish farming cages, seawater intake screens, and ship and boat hulls.</p>
        <p>Please contact us if you have any enquiries for other materials such as aluminium, platinum, and other types of alloy.</p>
      </Section>
    </Article>
  )
}
