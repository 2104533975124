import React from 'react';
import styled from 'styled-components';
import Spin from './CircleBounce';
import { BorderRadiusProps, borderRadius, color } from 'styled-system';
import { ColorProps } from 'components/types/style-system';

type SpinViewProps = BorderRadiusProps & ColorProps & {
  waiting?: boolean,
  children?: React.ReactNode,
}

function SpinViewBase(props: SpinViewProps & {className?: string}){
  const {className, waiting} = props;
  return(
   <div className={className}>
     {waiting && (
      <div className='waiting'>
        <Spin size="m"/>
      </div>
     )}
     {props.children}
   </div>
  );
}

export default styled(SpinViewBase)`
  position: relative;
  & > .waiting{
    position: absolute;
    display: flex;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(255,255,255,.7);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    ${borderRadius}
    ${color}
  }
`
