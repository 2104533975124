import styled from 'styled-components';
import { color, boxShadow, width, WidthProps, BoxShadowProps } from 'styled-system';
import responsive from '../utils/stylefn/responsive';
import { ColorProps } from 'components/types/style-system';

/**
 * Sidebar container
 */
export type DrawerProps = WidthProps & ColorProps & BoxShadowProps & {
  toggle?: boolean;
  responsive?: any;
}

const Drawer = styled('aside')<DrawerProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  left:0;
  top:0;
  bottom:0;
  height: 100%;
  overflow: hidden;
  z-index: 5001;
  transform: translateX(-100%);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  ${width}
  ${color}
  ${boxShadow}
  ${responsive}
  ${({toggle})=>{
    return toggle ? {
      transform: 'translateX(0)'
    }:{
      boxShadow: 'none'
    }
  }}
`

Drawer.defaultProps = {
  width: 300,
  boxShadow: "d2",
  bg: 'white'
}

export default Drawer
