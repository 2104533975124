import React from 'react';
import styled from 'styled-components';
import { color, space, boxShadow, SpaceProps, BoxShadowProps } from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import colorutil from 'color';
import Icon from '../icon';

//====================
// Icon Button
//====================
export type IconButtonProps = {
  variant?: string,
  type?: 'icon' | 'contained',
  hover?: boolean,
  focus?: boolean,
  icon?: string,
  children?: React.ReactNode,
  onClick?: React.MouseEventHandler,
}

function IconButtonBase (props:IconButtonProps & {className?: string}){
  const { className, icon, onClick, children } = props;
  return (
    <button className={className} onClick={onClick}>
      { icon ? (<Icon>{icon}</Icon>) : children}
    </button>
  );
}

const IconButton = styled(IconButtonBase)<IconButtonProps & SpaceProps & BoxShadowProps & ColorProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height:48px; */
  padding: 12px;
  border: none;
  border-radius: 50%;
  line-height: 24px;
  cursor: pointer;
  appearance: none;
  user-select: none;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background-color: transparent;
  ${Icon}{
    width:24px;
    height: 24px;
  }

  & > * {
    position: relative;
    z-index: 100;
  }

  &::before{
    position: absolute;
    width: 100%;
    height: 100%;
    content: " ";
    border-radius:24px;
    z-index: 0;
    transform: scale(0);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }

  &:focus{
    outline: none;
  }

  &:active:enabled{
    &:before{
      transform: scale(1)
    }
  }

  ${(props)=>{
    const { hover, focus, type, variant } = props;
    const { variants } = props.theme.IconButton;
    const theme = (variant && variants[variant]) || variants.default;

    switch(type){
      case 'contained':
      return {
          backgroundColor: theme.color,
          color: theme.inverse,
          "&:active":{
            "&::before":{
              backgroundColor: colorutil(theme.inverse).alpha(0.3).rgb().string(),
            }
          },
          ...(hover && {
            "&:hover:enabled":{
              backgroundColor: colorutil(theme.color).alpha(0.8).rgb().string(),
            }
          }),
        }

      default:
        return {
          color: theme.color,
          "&:active":{
            "&::before":{
              backgroundColor: colorutil(theme.color).alpha(0.2).rgb().string(),
            }
          },
          ...(hover && {
            "&:hover:enabled":{
              backgroundColor: colorutil(theme.color).alpha(0.1).rgb().string(),
            }
          }),
          ...(focus && {
            "&:focus":{
              backgroundColor: colorutil(theme.color).alpha(0.1).rgb().string(),
            }
          })
        };
    }
  }}
  ${ color }
  ${ space }
  ${ boxShadow }
`

IconButton.defaultProps={
  type: 'icon',
  hover: true,
  focus: false,
}

export default IconButton;
