import React from 'react';
import styled from 'styled-components';
import {
  width,
  height,
  color,
  ColorProps,
  space,
  SpaceProps,
  WidthProps,
  HeightProps
} from 'styled-system';

const Container = styled('div')<ScrollViewProps>`
  position:relative;
  flex: 1;
  overflow: hidden;
  ${color}
  ${width};
  ${height};
  ${space};
  & > div{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`

interface ScrollViewProps extends WidthProps, ColorProps, HeightProps, SpaceProps {
  children? : React.ReactNode,
  color? : string,
  bg? : string,
}

export default function ScrollView(props: ScrollViewProps){
  const {children, ...containerProps} = props;
  return (
    <Container {...containerProps}>
      <div>
        {children}
      </div>
    </Container>
  );
}
