import React from 'react';
import styled from 'styled-components';
import { SpaceProps, space, WidthProps } from 'styled-system';
import { Flex, View } from 'components';
import { createCell } from 'components/grid';

import { NavPage } from '../common/Pages';
import { Article, Banner, Section } from 'pages/common/Article';
import { H1 } from 'pages/common/components';
import Footer from 'pages/common/Footer';


export default function Products(){
  return (
    <NavPage appbarActive={false}>
      <Article>

        <Banner
          color="white"
          height="800px"
          backgroundImage="url(/assets/service.jpg)">
          <Flex height="100%" alignItems="center" justifyContent="center">
            <Section>
              <H1>
                We provide consulting and services in contract and project management.
                As a <b>one-stop business solution</b>, we can manage customers’
                contracts and projects from supply chain to the end-users.
              </H1>
            </Section>
          </Flex>
        </Banner>

        <Section>

          <Row my="3rem">
            <Cell span={2}>
              <h3>Contract Management</h3>
              <P>The implication and process of contract management includes:</P>
              <UL>
                <li>Loading process: monitoring cargoes loading at origin (if cargoes are from overseas)</li>
                <li>Shipment: finalising and outsourcing vessel spaces or container bookings to arrange shipment (if materials are purchased from overseas)</li>
                <li>Liaising with overseas and local freight services: for customs clearance purpose of overseas shipment</li>
                <li>Logistics: delivery arrangement with local transport</li>
                <li>Punctuality of arrival: ensure cargoes or products arrive on-time at the customers’ sides</li>
              </UL>
            </Cell>
            <Cell span={2} pt="2rem">
              <Banner  width="100%" height="90%" backgroundImage="url(assets/cm.jpg)"/>
            </Cell>
          </Row>

          <Row my="3rem">
            <Cell span={2} pt="2rem">
              <Banner  width="100%" height="90%" backgroundImage="url(assets/pm.jpg)"/>
            </Cell>

            <Cell span={2}>
              <h3>Project Management</h3>
              <P>Project management could also involve procurement process if customers appoint us to manage certain project from the beginning. This means our team will liaise closely with the mills, factories, suppliers, vendors, contractors, logistics companies, shipping line companies, customers project engineering sides, and end-users.</P>
              <P>Our team is dedicated, knowledgeable, passionate, and experience in managing projects, the projects history such as OSX-2 (OSX), Quad-204 (BP), and Newmont Batu Hijau Copper Mining.</P>
              <P>The implication and process of project management includes:</P>
              <UL>
                <li>Project analysis</li>
                <li>Requisition and procurement</li>
                <li>Kick-off meeting arrangement at the mills</li>
                <li>Operations and monitoring cargoes readiness from production</li>
                <li>Weekly operations and progress update</li>
                <li>Inspection and testing if required and applicable</li>
                <li>Shipping arrangement</li>
                <li>Customs clearance arrangement</li>
                <li>Logistics arrangement includes collecting cargoes from the wharf, unpacking, and delivering</li>
                <li>Documentation such as vendor data book, shipping documents, and system recording</li>
                <li>Traceability list includes production and cargoes readiness update, shipment schedules,documentation updates, mill test certificates (MTC) availability, delivery schedules, and projected of projects completion updates</li>
              </UL>
            </Cell>
          </Row>

          <Row my={4}>
            <Cell span={2}>
              <h3>Quality and Testing</h3>
              <P>On top of consulting and services like contract and project management, we also carry out and prioritise quality checking as well as running through several tests to ensure products is fulfilled with customers and projects’ requirement. This involves:</P>
              <UL>
                <li>Product analysis</li>
                <li>Hardness tests</li>
                <li>Bend or ductility tests</li>
                <li>Impact tests: charpy impact test and izod impact test</li>
                <li>Tensile tests: Z test, longitudinal test, weld tensile test, and tension test</li>
              </UL>

            </Cell>
            <Cell span={2} pt="2rem">
              <Banner  width="100%" height="90%" backgroundImage="url(assets/qt.jpg)"/>
            </Cell>
          </Row>
        </Section>
        <Footer/>
      </Article>
    </NavPage>
  );
}

const P = styled.p`
  color: #999;
  font-weight: 300;
`

const UL = styled.ul`
  list-style: none;
`

const Cell = createCell(View);

const Row = styled('div')<SpaceProps & WidthProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  ${space}
`

Row.defaultProps = {
  width: ['100%', '100%', '960px']
}

