import React  from 'react';
import styled from 'styled-components';
import { Screen, Mask, PosProps, H1, Subtitle, ContentRoot } from '../common/components';
import interpolation from 'components/utils/interpolation';

export default function Screen02(props:PosProps){
  const maskOpacity = interpolation([[0,1],[0.5,0.7], [0.7,0]])(props.pos);
  return (
    <Screen bg="gray1">
      <Mask bg="black" opacity={maskOpacity}/>
      <Img src="/assets/s02_cnr_br.png" alt="cnr"/>
    </Screen>
  )
}

export const Content = (props: PosProps)=>(
  <ContentRoot>
    <H1>
      We are the <b>Expert</b> helps the <b>Experts</b>
    </H1>
    <Subtitle>
      With more than 10 years of professional experience in product
      sourcing. Our team can help you in finding the right material and steel
      products from Australia and all over the world.
    </Subtitle>
  </ContentRoot>
);


const Img = styled.img`
  position: absolute;
  right:0;
  bottom:0;
  width: 300px;
`
