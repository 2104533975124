import React from 'react';
import styled from 'styled-components';
import { space, color, SpaceProps, ColorProps } from 'styled-system';
import Icon from '../icon/Icon';
import { CircleBounce as Spin } from '../spins';
import {  } from '@storybook/addon-knobs';


//====================
// Button
//====================
export type ButtonTypes = 'contained' | 'outline' | 'text';

export type ButtonProps = {
  icon?: string,
  waiting?: boolean,
  disabled?: boolean,
  variant? : string,
  type?: ButtonTypes,
  hover?: boolean,
  focus?: boolean,
  children?: React.ReactNode,
  onClick?: React.MouseEventHandler,
}

function ButtonBase(props: ButtonProps & {className?: string}){
  const { icon, children, waiting, disabled, className, onClick } = props;
  const getWaiting = ()=> waiting ? (<Spin size="s"/>) : null;
  const getIcon = ()=> icon ? (typeof(icon) === 'string' ? (<Icon>{icon}</Icon>) : icon ) : null;
  return (
    <button className={className} disabled={!!disabled || waiting} onClick={onClick}>
      <div>
        { getWaiting() || getIcon() }
        { children }
      </div>
    </button>
  );
}

const Button = styled(ButtonBase)<ColorProps & SpaceProps & ButtonProps>`
  position: relative;
  display: inline-block;
  padding: 6px 16px;
  vertical-align: middle;
  text-align: center;
  min-width: 64px;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.09rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  user-select: none;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  & > div {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
  }

  &::before{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    content: " ";
    z-index: 0;
    transform: scale(0);
    transition: all 0.2s cubic-bezier(.25,.8,.25,1);
  }

  &:focus{
    outline: none;
  }

  &:active{
    &::before{
      transform: scale(1);
    }
  }
  & ${Icon} {
    vertical-align: middle;
    margin-right: 9px;
  }

  & ${Spin} {
    margin-right: 9px;
  }



  ${(props)=>{
    const { type, hover, focus, variant } = props;
    const { variants } = props.theme.Button;
    const theme = {...variants.default, ...(variant && variants[variant])}

    let defaultStyle = {
      color: theme.color,
      backgroundColor: "transparent",
      "&:active:enabled": {
        "&::before":{
          backgroundColor: theme.mute,
        }
      },
      "&:disabled": {
        color: theme.mute,
      },
      [`& ${Spin}`]:{
        "span::before": {
          backgroundColor: theme.color
        }
      },
      ...(hover && {
        "&:hover:enabled":{
          boxShadow: theme.shadow
        }
      }),
      ...(focus && {
        "&:focus":{
          boxShadow: theme.shadow
        }
      })
    }

    switch(type){
      case 'contained':
        return {
          ...defaultStyle,
          backgroundColor: theme.color,
          color: theme.inverse,
          "&:active:enabled": {
            "&::before":{
              backgroundColor: theme.muteInverse
            }
          },
          "&:disabled":{
            backgroundColor: theme.mute,
          },
          [`& ${Spin}`]:{
            "span::before": {
              backgroundColor: theme.inverse
            }
          }
        }

      case 'outline':
        return {
          ...defaultStyle,
          border: "1px solid",
          borderColor: theme.border
        }

      default:
        return defaultStyle
    }
  }}

  ${space}
  ${color}
`

Button.defaultProps = {
  type: 'text',
  focus: false,
  hover: false,
}

export default Button;
