import React from 'react';
import styled, {css} from 'styled-components';
import {space, color, SpaceProps} from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import Checkbox from './Checkbox';


/**
 * Group Container
 */
type ContainerProps = SpaceProps & ColorProps & { itemWidth?: string }
const Container = styled.section<ContainerProps>`
  display: flex;
  flex-direction : row;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 400;

  ${({itemWidth, theme})=>css`
    ${Checkbox}{
      line-height: 2rem;
      width: ${itemWidth};
    }
  `}
  ${space}
  ${color}
`

/**
 * CheckBoxGroup
 * @param {object} props - React Props
 */
type OptionsType = Array<string | {label: string, value: string}>

export type CheckboxGroupProps = ContainerProps & {
  disabled?: boolean,
  options: OptionsType,
  values: Array<string>,
  onChange?: (value:Array<string>)=>void,
}

export function CheckboxGroup(props:CheckboxGroupProps){
  let { onChange, disabled, ...containerProps} = props;
  const values = props.values || [];
  const options = props.options.map(v=>typeof(v) === 'string' ? {label:v, value:v} : v) as Array<{label: string, value: string}>;
  const handleItemClick = (v:string)=>()=>{
    const nextValue = options.filter(o=>(((v === o.value) !== ( o.value && values.indexOf(o.value) >= 0)))).map(o=>o.value)
    onChange && onChange(nextValue)
  }
  return (
     <Container {...containerProps as ContainerProps}>
      {options.map((option, i)=>(
        <Checkbox
          key={i}
          onClick={handleItemClick(option.value)}
          label={option.label}
          checked={values.indexOf(option.value) >= 0}
          disabled={disabled}/>
      ))}
    </Container>
  )
}

CheckboxGroup.defaultProps = {
  itemWidth: '100%'
}

/**
 * RadioGroup
 */
export type RadioGroupProps = ContainerProps & {
  disabled?: boolean,
  options: OptionsType,
  value?: string,
  onChange?: (value:string)=>void,
}

export function RadioGroup(props: RadioGroupProps){
  const {value, onChange, disabled, itemWidth, ...rest} = props;
  const options = props.options.map(v=>typeof(v) === 'string' ? {label:v, value:v} : v);
  return (
    <Container {...rest} itemWidth={itemWidth}>
      {options.map((option, i)=>(
        <Checkbox
          key={i}
          onClick={(e)=>onChange && onChange(option.value)}
          label={option.label}
          checked={option.value === value}
          disabled={disabled}
          variant='radio'/>
      ))}
    </Container>
  );
}

RadioGroup.defaultProps = {
  itemWidth: '100%'
}
