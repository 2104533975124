import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="blue5" title="Plates"/>
      <Section>
        <p>
          Steel plate is a steel sheet material that used for customisation cut and weld to produce finished-steel products. Steel plate is constructed by flattening and compressing numbers of steel layers together to form a steel sheet of plate. It is often used to strengthen building construction or foundation, and support mass units of weights such as bridges, oil rigs, and floating production storage and offloading (FPSO) vessels.
        </p>
        <p>
          Most of the steel plates that we source will be the material of carbon steel. Nevertheless, we are also able to provide service in sourcing stainless steel, titanium, and other types of materials.
        </p>
        <p>
          The demand and application of steel plate is vary, depending on the customers and projects requirement. Products specifications and compositions are also the factors in determining types of steel plate that need to be applied and used in certain projects and applications. The types of steel plate can be:
        </p>
        <ul>
          <li>Mild steel plates</li>
          <li>Chequered plates</li>
          <li>High tensile plates</li>
        </ul>
        <p>
          We supply and assist to source steel plates that are in accordance to AS/NZ 3678, ASTM A36, JIS G 3101 SS400, EN 10025 S275, EN 10025 S355JR, ABS Grade A.
        </p>
      </Section>
    </Article>
  )
}
