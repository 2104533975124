import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';
import { Table } from 'components';

export default function(){
  const columns=[{
    key: 'material',
    label: 'Pipes Materials'
  },{
    key: 'grade',
    label: 'Specification and Grade'
  }]


  const data =[{
    material: "Carbon Steel",
    grade: "ASTM A53 Grade B, Grace C",
  },{
    material: "Carbon Steel",
    grade: "API 5L Grade B",
  },{
    material: "Carbon Steel",
    grade: "API 5L X52, X60, X65, X70",
  },{
    material: "Carbon Steel",
    grade: "API 5L X42N, X52N, X60Q, X65Q",
  },{
    material: "Carbon Steel",
    grade: "A/SA106 Grade B, Grade C",
  },{
    material: "Carbon Steel",
    grade: "EN 10216-2 P235GH",
  },{
    material: "Carbon Steel Low Temperature",
    grade: "A/SA333 Grade 3, Grade 6",
  },{
    material: "Alloy Steel, Chrome Molly",
    grade: "A/SA335 P1, P5, P9, P11, P12, P22, P91, P92",
  },{
    material: "Stainless Steel",
    grade: "ASTM 312 TP 304/304L, 316/316L, 304H, 316H, 309S, 310S, 317L, 321H, 347H",
  },{
    material: "Duplex",
    grade: "A/SA790 UNS S31803, S32205",
  },{
    material: "Super Duplex",
    grade: "A/SA790 UNS S32750, S32760",
  },{
    material: "Nickel Alloy",
    grade: "B/SB165 Monel 400 N04400",
  },{
    material: "Nickel Alloy",
    grade: "B/SB167 Inconel 600 N06600",
  },{
    material: "Nickel Alloy",
    grade: "B/SB444, B/SB704 Inconel 625 N06625",
  },{
    material: "Nickel Alloy",
    grade: "B/SB407 Incoloy 800 N08800, Incoloy 800H N08810, Incoloy 800HT N08811",
  },{
    material: "Copper Nickel",
    grade: "A/AS B466/B467 UNS C70600 (CuNi 90/10), C71500 (CuNi 70/30)",
  }]



  return(
    <Article>
      <Banner bg="black" title="Pipes"/>
      <Section>
        <p>RedMint Steel supplies a comprehensive range of steel piping materials including seamless and welded pipes. Besides, we could also supply black and galvanised pipes to suit customers’ necessities.</p>
        <p>Seamless pipe is pipe that does not have any welding seam. It is formed from a cylindrical bar of steel which is heated to high temperature and then pierced to create a hole through the cylinder. While, welded pipe is formed by rolling plate and welded seam. It is commonly called as Electric Resistance Welding (ERW), or Electric Fusion Welding (EFW). The weld flash can be removed from the inner and outer surfaces using a scarfing blade.</p>
        <p>Black pipe is made of steel that has not been galvanised and it is uncoated. The dark color comes from the iron-oxide formed on its surface during manufacturing. The main purpose of black pipe is to carry propane or natural gas into residential homes and commercial buildings. While, galvanised pipe is pipe that is coated with a zinc material to make the steel pipe more resistant to corrosion. Galvanised pipe is commonly used in scaffolding frames because of its resistance to corrosion.</p>
        <p>We could supply and assist to source different materials of steel piping:</p>

        <ul>
          <li>Carbon steel pipes</li>
          <li>Stainless steel pipes</li>
          <li>Duplex pipes</li>
          <li>Super duplex pipes</li>
          <li>Nickel alloy pipes</li>
          <li>Copper nickel pipes</li>
        </ul>

        <p>All steel pipes are available in standard and non-standard wall thicknesses, this is according to the customers and projects requirement. Mostly, the product specification will be in accordance to:</p>
        <ul>
          <li>DIN / EN pipes</li>
          <li>ASTM / ASME / API pipes</li>
        </ul>

        <Table width="100%" columns={columns} data={data}/>

      </Section>

    </Article>
  )
}
