import React from 'react';
import styled from 'styled-components';
import { View } from 'components';
import { SubtitleAlt } from 'components/typography';

type VideoProps = {
  id: string,
  title?: string,
}

/**
 * Video Component wrap around react-youtube
 *
 * @param {object} props React props
 */
export default function Video(props:VideoProps){
  const {id, title} = props;
  return (
    <View my={4} mx="auto">
      <VideoContainer>
        <iframe src={`https://www.youtube.com/embed/${id}`} frameBorder="0" title="Youtube"/>
      </VideoContainer>
      {title && (<SubtitleAlt textAlign="center" mt={2}>{title}</SubtitleAlt>)}
    </View>
  );
}

const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  & > iframe{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
`
