import React from 'react';
import { View } from 'components';
import { Screen, ContentRoot, H1, Subtitle } from '../common/components';

export default function Screen04(){
  return (
    <Screen backgroundImage='url(/assets/s04_bg.jpg)'/>
  );
}

export function Content(){
  return (
    <ContentRoot color="white">
      <View p='2rem'>
        <H1>What make us <b>different</b>?</H1>
        <Subtitle >
          RedMint Steel’s main objective is to assist local and worldwide companies in the <b>contract and project management</b> process with the goal of maximising and optimising companies’ available resources.
          This is particularly for mining, oil and gas, fabrication and engineering, FPSO (Floating Production Storage and Offloading) vessels, construction and building products, hardware and heavy machineries,
          as well as other steel trading companies.
        </Subtitle>
      </View>
    </ContentRoot>
  );
}
