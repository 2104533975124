import Pipes from './Pipes';
import Fittings from './Fittings';
import Flanges from './Flanges';
import Plates from './Plates';
import Sections from './Sections';
import WeldedBeams from './WeldedBeams';

import CarbonSteels from './CarbonSteels';
import StainlessSteels from './StainlessSteels';
import Duplex from './Duplex';
import SuperDuplex from './SuperDuplex';
import NickelAlloys from './NickelAlloys';
import CopperNickel from './CopperNickel';

export interface ProductType {
  id:string,
  name:string,
  cover: string,
  desc: string,
  content?: React.ComponentType<any>,
}

export const products:Array<ProductType> = [{
  id: 'pipes',
  name: 'Pipes',
  cover: "/assets/products/pipe.jpg",
  desc: "We supplies steel piping materials including seamless and welded pipes",
  content: Pipes,
},{
  id: 'fittings',
  name: 'Fittings',
  cover: "/assets/products/fittings.jpg",
  desc: "Pipe fitting is pipe connector which helps to attach one pipe to another in order to extend or terminate pipe runs",
  content: Fittings,
},{
  id: 'flanges',
  name: 'Flanges',
  cover: "/assets/products/flanges.jpg",
  desc: "A steel flange is a forged or cast ring of steel which cut or rolled from plate",
  content: Flanges,
},{
  id: 'plates',
  name: 'Plates',
  cover: "/assets/products/plates.jpg",
  desc: "Steel plate is a steel sheet material that used for customisation cut and weld to produce finished-steel products",
  content: Plates,
},{
  id: 'sections',
  name: 'Sections',
  cover: "/assets/products/sections.jpg",
  desc: "Steel section is steel that manufactured, processed, and fabricated by convertible steel",
  content: Sections,
},{
  id: 'welded_beams',
  name: 'Welded Beams',
  cover: "/assets/products/welded_beams.jpg",
  desc: "Welded beam is one of the structural steel sections which is incorporated and welded by three steel plates together",
  content: WeldedBeams,
}]

export const materials:Array<ProductType> = [{
  id: 'carbon',
  name: 'Carbon Steels',
  cover: "/assets/products/carbon_steels.jpg",
  desc: "Carbon steels are alloys made of irons and carbons",
  content: CarbonSteels,
},{
  id: 'stainless',
  name: 'Stainless Steels',
  cover: "/assets/products/stainless_steels.jpg",
  desc: "Stainless steels are alloys made of iron with a minimum of 10.5% Chromium",
  content: StainlessSteels,
},{
  id: 'duplex',
  name: 'Duplex',
  cover: "/assets/products/duplex.jpg",
  desc: "Duplex is stainless steel that has a two-phase microstructure",
  content: Duplex,
},{
  id: 'super_duplex',
  name: 'Super Duplex',
  cover: "/assets/products/super_duplex.jpg",
  desc: "Super duplex is a mixed microstructure of austenite and ferrite",
  content: SuperDuplex,
},{
  id: 'nickel_alloys',
  name: 'Nickel Alloys',
  cover: "/assets/products/nickel_alloys.jpg",
  desc: "Nickel is a versatile element that will blend with most alloys and metals",
  content: NickelAlloys,
},{
  id: 'copper_nickel',
  name: 'Copper Nickel',
  cover: "/assets/products/cooper_nickel.jpg",
  desc: "Copper nickel is one of the alloys type that has high resistance to seawater environments",
  content: CopperNickel,

}]
