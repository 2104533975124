import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { Route } from 'react-router-dom';
import Icon from '../icon';



/**
 * Linkable interface
 */
interface LinkableType {
  to: string,
  exact?: boolean,
}

type TargetComponentProps = {
  selected?: boolean,
  onClick?: React.MouseEventHandler,
} & any;

function linkable<P extends LinkableType>(Target: React.ComponentType<TargetComponentProps>):React.ComponentType<P> {
  return (props:P)=>{
    const {to, exact, ...targetProps} = props;
    return (
      <Route
        path={to}
        exact={exact}
        children={({match, history})=>(
          <Target selected={!!match} onClick={()=>history.push(to)} href={to} {...targetProps}/>
        )}/>
    );
  };
}


/**
 * MenuItem
 */

export type MenuItemProps = {
  disabled? : boolean,
  selected? : boolean,
  icon? : string | boolean,
  children? : React.ReactNode,
  onClick?: MouseEventHandler,
  className?: string,
  variant?: 'primary',
}

function MenuItemBase(props: MenuItemProps){
  const {icon, children, disabled, onClick, ...itemProps} = props;
  return(
    <li {...itemProps} onClick={!disabled ? onClick : undefined}>
      {icon && (<Icon>{icon}</Icon>)}
      {children}
    </li>
  );
}

export const MenuItem = styled(MenuItemBase)<MenuItemProps>`
  padding: 0 1rem;
  border-radius: 5px;
  line-height: 2rem;
  margin: 0.5rem;
  vertical-align: middle;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 300ms;
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;

  & > ${Icon} {
    line-height: inherit;
    width: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
  }

  ${(props)=>{
    const {disabled, selected, variant} = props;
    const { variants } = props.theme.Drawer;
    const theme = {...variants.default, ...(variant && variants[variant])}


    if(disabled){
      return css`
        color: ${theme.disabled};
        cursor: default;
      `
    }

    if(selected){
      return css`
        color: ${theme.selected};
        background-color: ${theme.selectedBg}
      `
    }

    return css`
      color: ${theme.color};
      &:hover{
        color: ${theme.hover};
      }
    `
  }}
`

/**
 * Menu link
 */
export type MenuLinkType = MenuItemProps & LinkableType;
export const MenuLink = linkable<MenuLinkType>(MenuItem);

/**
 * Menu
 */
const Menu = styled.ul`
  display: block;
  padding:0 0.5rem;
  margin:0;
  list-style: none;
  &>li{
    list-style: none;
  }
`

export default Menu
