import styled from 'styled-components';
import { Text } from 'components/typography';
import { ColorProps } from 'components/types/style-system';
import { SpaceProps, space, color, BoxShadowProps, boxShadow, BackgroundImageProps, backgroundImage, OpacityProps, opacity, width, WidthProps } from 'styled-system';

//-----------------------
// Typography
//-----------------------

/**
 * H1
 */
export const H1 = styled(Text)`
  font-weight: 200;
  letter-spacing: ${-0.5/60}rem;
  b{
    font-weight: 500;
  }
  em{
    font-weight: 300;
    font-style: normal;
    color: red;
  }
`

H1.defaultProps = {
  color: 'inherit',
  fontSize: ['2rem', '3rem', '4rem'],
  as: 'h1',
}

/**
 * Subtitle
 */
export const Subtitle = styled(Text)`
  font-weight: 300;
  line-height: 1.5;
`

Subtitle.defaultProps={
  fontSize: ['1rem', '1.4rem', '1.7rem'],
  color: "gray5",
}

/**
 * Body
 */
export const Body = styled(Text)`
  letter-spacing: ${0.5/16}rem;
  font-weight: 300;
`

Body.defaultProps = {
  color: 'black',
  lineHeight: 1.8,
}



//-----------------------
// Utilities
//-----------------------
type ScreenProps = ColorProps & SpaceProps & BoxShadowProps & BackgroundImageProps;

export const Screen = styled.section<ScreenProps>`
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  ${space}
  ${color}
  ${boxShadow}
  ${backgroundImage}
`

Screen.defaultProps={

}

export const Mask = styled.div<OpacityProps & ColorProps>`
  position: absolute;
  left:0;
  top:0;
  bottom:0;
  right: 0;
  z-index: 10;
  ${color}
  ${opacity}
`

export const ContentRoot = styled('section')<WidthProps & OpacityProps & ColorProps>`
  display: flex;

  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  color: white;
  ${opacity}
  ${color}
  ${width}
`

ContentRoot.defaultProps={
  color:'black',
  width: ["100%", "100%", "90%", "90%", "50%"]
}


//-----------------------
// types
//-----------------------

export type PosProps = {
  pos: number,
}
