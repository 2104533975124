import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="blue5" title="Flanges"/>
      <Section>
        <p>
          A steel flange is a forged or cast ring of steel which cut or rolled from plate and it is designed to connect or joint mechanical sections of pipe to pressure vessel, pump, valve, and other flange-assembled steel accessories. Stub ends, bolts, and nuts are usually used to join flanges together; this is especially reflected in the piping system.
        </p>
        <p>
          When choosing a suitable flange for an application, we will need to consider flange face as a pivotal factor. The flange faces are Flat Face (FF), Raised Face (RF), and Ring Type Joint (RTJ). Alongside with the flange faces, we supply several kinds of flanges with different pressure ratings, such as:
        </p>
        <ul>
          <li>Slip-on</li>
          <li>Blind flange</li>
          <li>Welding neck</li>
          <li>Threaded flange</li>
          <li>Socket weld flange</li>
          <li>Lap joint</li>
          <li>Spectacle blind flange</li>
          <li>Spades and spacers</li>
          <li>Nipoflange and weldoflange</li>
        </ul>
        <p>
          The steel flanges that we supply and source will be in accordance to:
        </p>
        <ul>
          <li>DIN / EN flanges</li>
          <li>ASTM / ASME / API flanges</li>
        </ul>
      </Section>
    </Article>
  )
}
