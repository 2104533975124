import React from 'react';
import Landing from './landing';
import { ProductIndex, Product } from './products';
import Services from './services';
import Contacts from './contacts';

import { Switch, Redirect, Route } from 'react-router-dom';

export default function Page(){
  return (
    <Switch>
      <Route exact path="/" component={Landing}/>
      <Route exact path="/products" component={ProductIndex}/>
      <Route exact path="/products/:productId" component={Product}/>
      <Route path="/services" component={Services}/>
      <Route path="/contacts" component={Contacts}/>
      <Route render={()=>(<Redirect to="/"/>)} />
    </Switch>
  );
}
