import styled from 'styled-components';
import { space, SpaceProps, color } from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import { MouseEventHandler } from 'react';


const SIZES = {
  xs: 18,
  s: 24,
  m: 36,
  l: 64
}

const VARIANTS = {
  square:{
    borderRadius: 0,
  },
  circle: {
    borderRadius: '50%'
  }
}

export type AvatarProps = SpaceProps & ColorProps & {
  size?: 'xs' | 's' | 'm' | 'l',
  variant?: 'square' | 'circle',
  src?: string | null,
  onClick?: MouseEventHandler,
}

const Avatar = styled('span')<AvatarProps>`
	display: inline-block;
	text-align: center;
	background-color: #999;
  background-size: cover;
  cursor: pointer;
  ${space}
  ${color}
  ${(props)=>{
    const { src } = props;
    const size = SIZES[props.size || 'm'];
    const backgroundImage = src ? `url(${src})` : undefined;
    return {
      backgroundImage,
      width: size,
      height: size,
      lineHeight: `${size}px`,
      fontSize: size*2/3,
      ...VARIANTS[props.variant || 'square']
    }
  }}
`
export default Avatar;
