import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="blue5" title="Welded Beams"/>
      <Section>
        <p>
          Welded beam is one of the structural steel sections which is incorporated and welded by three steel plates together that consists top, web, and bottom flange. The application of this section can be for building constructions, bridges, floor decks, cranes, sport stadiums, and other structures works.
        </p>
        <p>
          We could assist to source welded beams that are in accordance to AS/NZS 3679 Grade 300, 350, and 400.
        </p>
      </Section>
    </Article>
  )
}
