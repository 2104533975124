import React, { useState } from 'react';
import { SpaceProps, WidthProps, FlexProps} from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import isEmpty from 'lodash/isEmpty';
import Icon from '../icon/Icon';
import {Background, Container, VariantType} from './components';


export type InputFieldProps = SpaceProps & ColorProps & WidthProps & FlexProps & {
  type?: "text" | "number" | "password" | "textarea";
  variant?: VariantType;
  label?: string;
  leadingIcon?: string | React.ReactElement;
  trailingIcon?: string | React.ReactElement;
  helper?: string | boolean;
  error?: string | boolean;
  value?: string;
  rows?: number;
  disabled?: boolean;
  onChange?: (value:string)=>void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export default function InputField(props: InputFieldProps){
  const {
    variant,
    type,
    label,
    leadingIcon,
    trailingIcon,
    helper,
    error,
    value,
    disabled,
    onChange,
    onBlur,
    rows,
    ...containerProps
  } =  props;

  const [focus, setFocus] = useState(false);
  const [empty, setEmpty] = useState(isEmpty(value));

  const handleChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    setEmpty(isEmpty(e.target.value));
    onChange && onChange(e.target.value);
  }

  const handleFocus = (e:any)=>{
    setFocus(true);
  }

  const handleBlur = (e:any)=>{
    setFocus(false);
    onBlur && onBlur(e);
  }

  const getIcon = (icon: string | React.ReactElement) : React.ReactElement =>{
    if(typeof(icon) === 'string'){
      return <Icon>{icon}</Icon>
    }else{
      return icon;
    }
  }

  const isTextArea = type === 'textarea';

  const stateProps = {
    variant: variant,
    hasLeadingIcon: !!leadingIcon,
    hasTrailingIcon: !!trailingIcon,
    hasLabel: !isEmpty(label),
    hasFocus:  focus,
    active:  (focus || (value !== undefined ? !isEmpty(value) : !empty )),
    hasError: !!error,
    disabled: disabled
  }

  return (
    <Container
      {...stateProps}
      {...containerProps}>
      <div className={`field ${isTextArea? 'multiline':'singleline'}`}>
        {leadingIcon && <span className='leading'>{getIcon(leadingIcon)}</span>}
        {trailingIcon && <span  className='trailing'>{getIcon(trailingIcon)}</span>}
        {isTextArea ? (
          <textarea
            disabled={disabled}
            value={value}
            rows={rows || 3}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}/>
        ):(
          <input
            type={type}
            disabled={disabled}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}/>
        )}

        <Background label={label} variant={variant}/>
      </div>
      {(error || helper) && <footer>{ error || helper}</footer>}
    </Container>
  );
}

InputField.defaultProps = {
  color: '#444',
  variant: 'outlined'
}
