import React, { useState, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';
import { height, HeightProps } from 'styled-system';
import useMeasure from 'utils/hooks/useMeasure'
import ParallaxContext from './ParallaxContext';


/**
 * ParallaxLayer
 */

type LayerWrapperProps = {
  zIndex?: number,
  height?: number,
}

type RenderFn = {
  (range:number):React.ReactNode
}

export type ParallaxLayer = LayerWrapperProps & {
  offset?: number,
  speed?: number,
  sticky?: number,
  sync?: boolean,
  length?: number,
  render?: RenderFn,
  children?: React.ReactNode
}

const LayerWrapper = styled('section')<LayerWrapperProps>`
  position: absolute;
  width: 100%;
  ${(props)=>{
    const {zIndex, height} = props;
    return {
      zIndex,
      height,
    }
  }}
`
export function ParallaxLayer(props:ParallaxLayer){
  const { offset, speed, sticky, sync, zIndex, length } = props;
  const { bounds, scrollTop } = useContext(ParallaxContext);
  const height = bounds.height * length!;
  const revealed = scrollTop > height * offset!;

  const y = revealed && sync ?
    Math.min(scrollTop, (offset! + sticky!) * height) :
    height * (offset! + Math.floor(offset!) * speed!) - (scrollTop * speed!);

  return (
    <LayerWrapper
      style={{transform: `translate3d(0, ${y}px, 0)`}}
      zIndex={zIndex}
      height={height}>
      {props.render ? props.render((scrollTop - height * offset! )/height) : props.children }
    </LayerWrapper>
  );
}

ParallaxLayer.defaultProps = {
  offset : 0,
  speed: 0,
  sticky: 0,
  sync: true,
  zIndex: 0,
  length: 1,
}

/**
 * Parallax
 */


export type ParallaxProps = {
  pages: number,
  extra? : React.ReactNode,
  children?: React.ReactNode,
  onPageScroll?: Function,
}

export default function Parallax(props:ParallaxProps){
  const { pages, extra, children, onPageScroll} = props;
  const parallaxRoot = useRef(null);
  const bounds = useMeasure(parallaxRoot);
  const [scrollTop, setScrollTop] = useState(0);
  const contextValue = {bounds, scrollTop}
  const handleScroll = (e:any)=>{
    const scrollTop = e.target.scrollTop;
    setScrollTop(scrollTop);
    bounds.height && onPageScroll && onPageScroll(scrollTop/bounds.height)
  }

  const height = pages * bounds.height;

  return (
    <ParallaxRoot
      ref={parallaxRoot}
      onScroll={handleScroll}
      active={parallaxRoot.current !== null}>
      <Main height={height} >
        <ParallaxContext.Provider value={contextValue}>
          {children}
        </ParallaxContext.Provider>
      </Main>
      {extra}
    </ParallaxRoot>
  );
}

const ParallaxRoot = styled('div')<{ref:any, onScroll:Function, active:boolean}>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  transition: opacity 300ms ease-in;
  ${(props)=>props.active && css`
    opacity: 1;
  `}
`

const Main = styled.main<HeightProps>`
  position: relative;
  width: 100%;
  ${height}
  background-color: transparent;
`
