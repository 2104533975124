import React from 'react';
import range from 'lodash/range';
import styled, { keyframes } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { colorAsBackground, size, Size } from './stylefn';


//====================
// double bounce
//====================
type DoubleBounceProps  = SpaceProps & {
  size?: Size,
  color?: string,
}

function DoubleBounceBase(props:DoubleBounceProps & {className?: string}){
  return (
    <span className={props.className}>
      {range(0, 2).map(i=><span key={i}/>)}
    </span>
  );
}

const bounceAnim = keyframes`
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
`

const DoubleBounce = styled(DoubleBounceBase)<DoubleBounceProps>`
  position: relative;
  display: inline-block;
  background-color: transparent;
  ${size}
  ${space}
  & > span{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    ${colorAsBackground};
    opacity:0.6;
    animation: ${bounceAnim} 2.0s infinite ease-in-out;
    &:nth-child(2){
      animation-delay: -1.0s;
    }
  }
`

DoubleBounce.defaultProps = {
  color: 'gray8',
}

export default DoubleBounce;
