import findLastIndex from 'lodash/findLastIndex';

export type Point = [number, number];

export default function interpolation(points:Array<Point>){
  return (x:number ) => {
    const i = findLastIndex(points, ([px,py])=> x > px);

    if(i === -1){
      return points[0][1];
    }

    if( i === points.length -1){
      return points[points.length - 1][1];
    }

    const [x0,y0] = points[i];
    const [x1,y1] = points[i+1];
    const scale = (x - x0) / (x1 - x0)
    return y0 + (y1 - y0) * scale;
  }
}
