import React from 'react';
import styled from 'styled-components';
import { View, Flex, Icon } from 'components';
import { Caption, SubtitleAlt } from 'components/typography';

export default function Footer(){
  return (
  <View bg="gray2" color="black" p={4} as="footer">
    <Flex width="70%" mx="auto" mt={4} flexDirection={["column", "column", "row"]} p={4}>
      <AddressLine/>
      <BrandTas/>
    </Flex>
  </View>
  )
}

const BrandTasBase = (props:any)=>(
  <div className={props.className}>
    <img className="logo" src="/assets/brandtas.png" alt="brandtas"/>
    <SubtitleAlt my={2}>Member of Brand Tasmania</SubtitleAlt>
  </div>
)

const BrandTas = styled(BrandTasBase)`
  .logo{
    width: 170px;
  }
`

const AddressLineBase = (props:any)=>(
  <div className={props.className}>
    <img className="logo" src="/assets/logo1.png" alt="logo"/>
    <Caption>ABN 98 633 638 981</Caption>
    <View my={2}>
      <div>PO Box 355, Sandy Bay</div>
      <div>TAS 7006</div>
      <div>Australia</div>
      <Link href="mailto:info@redmintsteel.com.au">info@redmintsteel.com.au</Link>
    </View>
  </div>
);

const Link = styled.a`
display: flex;
align-items: center;
text-decoration: none;
color: #999;
margin-top: 0.5rem;
&:hover{
  color: #b8000f;
}
${Icon}{
  font-size: 1rem;
}

`

export const AddressLine = styled(AddressLineBase)`
  flex: 1;
  .logo{
    height: 30px;
  }
`


/**
 * Logo
 */
const Logo = styled.img`

`

Logo.defaultProps={
  src:"/assets/logo1.png"
}
