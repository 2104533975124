import React from 'react';

export type ContextValue = {
  bounds?: any,
  scrollTop: number,
}

const initialValue : ContextValue = {
  bounds: null,
  scrollTop: 0,
}

export default React.createContext<ContextValue>(initialValue);
