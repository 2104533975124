import React from 'react';
import NavigationDrawer, { NavigationDrawerProps } from 'components/drawer/NavigationDrawer';
import Menu, { MenuLink } from 'components/drawer/DrawerMenu';
import { View, Flex } from 'components';

type AppDrawerProps = NavigationDrawerProps & {

}

export default function AppDrawer(props: AppDrawerProps){
  const {toggle, setToggle} = props;
  return (
    <NavigationDrawer toggle={toggle} setToggle={setToggle}>
      <Flex height={200} mb={2} justifyContent="center" alignItems="center">
        <View>
          <img src="/assets/logo2.png" alt="logo" height="120"/>
        </View>
      </Flex>
      <Menu>
        <MenuLink to="/" exact>Home</MenuLink>
        <MenuLink to="/products" >Products</MenuLink>
        <MenuLink to="/services" >Services</MenuLink>
        <MenuLink to="/contacts" >Contact Us</MenuLink>
      </Menu>
    </NavigationDrawer>
  );
}
