import React from 'react';
import styled, { keyframes, } from 'styled-components';
import range from 'lodash/range';
import { space, SpaceProps } from 'styled-system';
import { colorAsBackground, size } from './stylefn';

//====================
// folding cube
//====================
type FoldingCubeProps = SpaceProps & {
  size?: string,
  color?: string,
}

function FoldingCubeBase(props: FoldingCubeProps & {className?: string}){
  return (
    <div className={props.className}>
      {range(0, 4).map(i=><span key={i}/>)}
    </div>
  );
}

const foldCubeAngle = keyframes`
 0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
`
const FoldingCube = styled(FoldingCubeBase)<FoldingCubeProps>`
  position: relative;
  display: inline-block;
  background-color: transparent;
  transform: rotateZ(45deg);
  ${size}
  ${space}

  & > span{
    position: relative;
    display: block;
    float: left;
    width: 50%;
    height: 50%;
    transform: scale(1.1);
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: ${foldCubeAngle} 2.4s infinite linear both;
      transform-origin: 100% 100%;
      ${colorAsBackground};
    }
    &:nth-child(2){
       transform: scale(1.1) rotateZ(90deg);
       &:before{
          animation-delay: 0.3s;
       }
    }

    &:nth-child(4){
       transform: scale(1.1) rotateZ(180deg);
       &:before{
          animation-delay: 0.6s;
       }
    }

    &:nth-child(3){
       transform: scale(1.1) rotateZ(270deg);
       &:before{
          animation-delay: 0.9s;
       }
    }
  }
`

FoldingCube.defaultProps = {
  color: 'blue5',
  size: 'small',
}

export default FoldingCube;
