import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="blue5" title="Sections"/>
      <Section>
        <p>
          Steel section is steel that manufactured, processed, and fabricated by convertible steel and metal into various types of sections and can be used in the different applications. There are several forms of sections:
        </p>
        <ul>
          <li>Angles</li>
          <li>Channels</li>
          <li>Flat bars</li>
          <li>Tee bars</li>
          <li>Deformed bars</li>
          <li>Universal beams</li>
          <li>Universal columns</li>
          <li>Welded beams</li>
          <li>Hollow sections: hot-finished and cold-formed</li>
        </ul>
        <p>
          We could assist to source structural sections that are in accordance to EN 10025 S275, EN10025 S355JR, JIS G3101, ASTM A36, Q235, Q345. This will be in carbon steel materials. Subsequently, we are also able to source for other steel materials.
        </p>
      </Section>
    </Article>
  )
}
