import reduce from 'lodash/reduce';
import { styleFn } from 'styled-system';

/**
 * @param {string} - color string can be Hex or color name
 * @returns HEX color value resolved from theme
 */
const toColor = (theme:any)=>(v:string)=>theme.colors[v] || v;

export interface HoverProps{
  hover?: string | {bg: string}
}

/**
 *
 * @param {props} - react component props
 */
const hover:styleFn = ({hover, theme})=>{
  const colors = toColor(theme);

  if(typeof(hover) === 'string'){
    return {'&:hover': {color: colors(hover)}}
  }

  if(typeof(hover) === 'object'){
    const { bg:background, color } = hover;
    return {'&:hover': reduce({background, color}, (style, val, key)=>(
      val ? {...style, [key]: colors(val)} : style
    ), {})}
  }

  return null;
}

export default hover;
