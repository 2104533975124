import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';
import { View } from 'components';

export default function(){
  return(
    <Article>
      <Banner bg="black" title="Fittings"/>
      <Section>
        <p>Pipe fitting is pipe connector which helps to attach one pipe to another in order to extend or terminate pipe runs, change pipe direction, connect two or more pipes, and manage or regulate flow in a plumbing and piping system. This is used to combine, divert or reduce the flow of water supply. Fittings come in different sizes, thicknesses, and pressure rating measurements to suit or fit into to the pipes.</p>
        <p>Buttweld and forged are the main types of fittings that are usually used in the piping system.</p>
        <ul>
          <li>Buttweld fittings are fittings that welded at their ends to pipe end, with the same size and thickness as pipes. The end of butt weld fittings usually will be bevelled.</li>
          <li>Forged fittings are fittings that use forging process to put pressure on blank metals, making it to  produce deformation in order to obtain certain mechanical properties, and a certain shape and size.</li>
        </ul>
        <p>We supply and assist to source all fittings in standard and non-standard size and wall thickness. The product specification will be in accordance to ASTM / ASME fittings and ASTM / ASME high pressure fittings.</p>
      </Section>

      <View bg="gray1">
        <Section>
          <h5>Buttweld Fittings</h5>
          <p>
            45<sup>&#176;</sup> short radius elbow, 45<sup>&#176;</sup> long radius elbow, 90<sup>&#176;</sup> short radius elbow, 90<sup>&#176;</sup> long radius elbow, equal tee, reducing tee, concentric and eccentric reducer, and cap.
          </p>
        </Section>
      </View>

      <Section>
        <h5>Forged Fittings (Socket Weld and Threaded)</h5>
        <p>
          45<sup>&#176;</sup> short radius elbow, 45<sup>&#176;</sup> long radius elbow, 90<sup>&#176;</sup> short radius elbow, 90<sup>&#176;</sup> long radius elbow, equal tee, reducing tee, cross, coupling, half coupling, cap, union, swage nipple, threadolet, sockolet, weldolet, nipolet/reinforced nipple.
        </p>
      </Section>

    </Article>
  )
}
