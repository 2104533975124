import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { color, space, SpaceProps, boxShadow, BoxShadowProps } from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import { BodyAlt } from '../typography';
import { IconButton } from '../buttons'

/**
 * Banner Props
 */

export type BannerProps = SpaceProps & ColorProps & BoxShadowProps & {
  message?: string,
  canDismiss?: boolean,
  children?: React.ReactNode,
}

function BannerBase(props: BannerProps & {className?: string}){
  const { message, children, canDismiss } = props;
  const [show, setShow] = useState(false);

  useEffect(()=>{
    setShow(true);
  },[message, children]);

  const handleDismiss = ()=>{
    setShow(false);
  }

  if(!show){
    return null;
  }

  return (
    <div className={props.className}>
      <span className='message'>
        {message ? (<BodyAlt>{message}</BodyAlt>) : children}
      </span>
      { canDismiss && (
        <IconButton color={props.color} icon="close" onClick={handleDismiss}/>
      )}
    </div>
  )
}

const Banner = styled(BannerBase)<BannerProps>`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: row;
  top:0;
  left:0;
  right:0;
  z-index: 1000;
  padding: 10px 1rem 8px 1rem;
  line-height: 1.5rem;
  .message{
    display: flex;
    flex:1;
  }

  ${color}
  ${space}
  ${boxShadow}
`

Banner.defaultProps = {
  canDismiss: true,
  bg:'white',
  color: 'black',
  boxShadow:"d1"
}

export default Banner;
