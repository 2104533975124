import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="tungsten" title="Carbon Steels"/>
      <Section>
        <p>
          Carbon steels are alloys made of irons and carbons, and carbon itself will be the main element of alloying. The carbon percentage can be vary depending on the grade and combinations of alloy metals to fulfil many purposes; such as low pressure conveyance of gas, water, oil, air steam or other fluids, machinery, buildings, building products, sprinkler systems, irrigation systems, and water wells.
        </p>
        <p>
          Carbon steels are often divided into three categories: (1) low temperature, (2) high temperature, and (3) high yield carbon steel. This is to differentiate and specially made to suit and meet the requirements of different industries, projects, and applications.
        </p>
        <p>
          Carbon steels can be galvanised, particularly through hot-dipped galvanising or electroplating in zinc for protection and rusty prevention.
        </p>
        <p>
          The benefits of using carbon steel:
        </p>
        <ul>
          <li>Cost effective than other materials of steel</li>
          <li>Containing the balances ductility and strength with good wear resistance</li>
          <li>Strong and it also can be tempered to great hardness</li>
        </ul>
        <p>
          There are numbers of carbon steel products that we could source and supply, such as pipes, fittings, flanges, steel plates, and structural sections with the different material grades. Please visit our product site for more details.
        </p>
      </Section>
    </Article>
  )
}
