import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import range from 'lodash/range';
import { space, SpaceProps } from 'styled-system';
import { colorAsBackground, Size } from './stylefn';

const SIZES = {
  xs: {width: 1, marginRight: 2},
  s: {width: 3, marginRight:3},
  m: {width: 7, marginRight:3},
  l: {width: 12, marginRight:4},
}

//====================
// stretch
//====================
type StretchProps = SpaceProps & {
  color?: string,
  period?: number,
  size?: Size,
}

function StretchBase(props: StretchProps & {className?: string}){
  return (
    <div className={props.className}>
      {range(0,5).map(i=><span key={i}/>)}
    </div>
  );
}

const stretchdelay = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
  }
`

const Stretch = styled(StretchBase)<StretchProps>`
  display: inline-block;
  text-align: center;
  font-size: 10px;
  & > span {
    display: inline-block;
    height: 100%;
    ${colorAsBackground}
  }

  ${(props)=>{
    const {period=1.2, size='s'} = props;
    const spanStyle = SIZES[size] || SIZES.s;
    const width = (spanStyle.width + spanStyle.marginRight) * 5;
    return css`
      width: ${width}px;
      height: ${width * 1.2}px;
      & > span{
        animation: ${stretchdelay} ${period}s linear infinite;
        ${()=>range(1,5).reduce((styles, i)=>{
            const deplay = period - (i*period/10)
            return {...styles, [`&:nth-child(${i+1})`]:{animationDelay: `-${deplay}s`}}
          }, spanStyle)
        }
      }
    `
  }}
  ${space}
`

Stretch.defaultProps = {
  period: 1.2,
  color: 'gray8',
  size: 's'
}

export default Stretch;
