import React from 'react';
import styled, { keyframes } from 'styled-components';
import range from 'lodash/range';
import { space, SpaceProps } from 'styled-system';
import { colorAsBackground, size, Size } from './stylefn';
import { ColorProps } from 'components/types/style-system';


//====================
// circle bounce
//====================
type SpinProps = SpaceProps & ColorProps & {
  size? : Size
}

function CircleBounceBase(props: SpinProps & {className?: string}){
  return (
    <span className={props.className}>
      {range(0, 12).map(i=><span key={i}/>)}
    </span>
  );
}

const circleBounceAnim = keyframes`
  0%, 80%, 100% {
    transform: scale(0.01);
    opacity: 0.9;
  } 40% {
    transform: scale(1);
    opacity: 1;
  }
`

const CircleBounce = styled(CircleBounceBase)<SpinProps>`
  position: relative;
  display: inline-block;
  background-color: transparent;
  ${size}
  ${space}

  & > span{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      ${colorAsBackground}
      border-radius: 100%;
      animation: ${circleBounceAnim} 1.2s infinite ease-in-out both;
    }
    ${()=>{
      return range(1,12).reduce((styles, i)=>{
        const key = `&:nth-child(${i})`;
        const degree = i*30;
        const delay = -1.2 + i*0.1;
        return {
          ...styles,
          [key]:{
            transform: `rotate(${degree}deg)`,
            '&:before':{
              animationDelay: `${delay}s`
            }
          }
        }
      },{});
    }}
  }
`

CircleBounce.defaultProps = {
  color: 'gray8',
  size: 's'
}

export default CircleBounce;
