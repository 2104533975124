import * as React from 'react';
import styled, {css} from 'styled-components';
import {color, space, SpaceProps} from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import { Caption } from '../typography';
import colorUtil from 'color';


/**
 * ProgressBar
 */

type ProgressBarProps = SpaceProps & ColorProps & {
  progress: number,
  className?: string,
}

function ProgressBarBase(props: ProgressBarProps){
  const { progress, className } = props;
  const pstr = `${Math.round(progress*100)}%`;
  return(
    <div className={className}>
      <div className="bar">
        <div className="inner" style={{width: pstr}}/>
      </div>
      <Caption>{pstr}</Caption>
    </div>
  );
}

const ProgressBar = styled(ProgressBarBase)<ProgressBarProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 60px;
  .bar{
    position: relative;
    display: inline-block;
    flex:1;
    margin-right: 1rem;
    height: 2px;
    & > .inner{
      position: absolute;
      left:0;
      top:0;
      bottom:0;
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }

    ${({theme, color})=>{
      const rgb = (color && theme.colors[color]) || color;
      return css`
        background-color: ${colorUtil(rgb).alpha(0.2).rgb().string()};
        & > .inner{
          background-color: ${colorUtil(rgb).alpha(0.7).rgb().string()};
        }
      `
    }}
  }
  ${color}
  ${space}
`

ProgressBar.defaultProps = {
  color: 'black'
}

export default ProgressBar;
