import React from 'react';
import styled from 'styled-components';
import { Screen, H1, Subtitle } from '../common/components';
import { View, LinkButton } from 'components';
import { space, SpaceProps } from 'styled-system';



export default function Screen05(){
  return (
    <Screen boxShadow="d3">
      <Img src="/assets/s07_pipe_lm.png" alt="lm"/>
    </Screen>
  );
}

export function Content(){
  return (
    <ContentRoot>
      <H1>
        More about our <b>Products</b>
      </H1>
      <Subtitle color="black">
        RedMint Steel supplies a comprehensive range of steel products for your projects, such as Pipes, Fittings, Flanges, Plates, Sections and Welded Beams.
      </Subtitle>
      <View mt={4}>
        <LinkButton to="/products" type="contained" >Learn more </LinkButton>
      </View>
    </ContentRoot>
  );
}

const Img = styled.img`
  position: absolute;
  top:50%;
  left: 0;
  transform: translateY(-50%);
  width: 400px;
  opacity: 0.5;
`
const ContentRoot = styled('section')<SpaceProps>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  color: #333;
  ${space}
`

ContentRoot.defaultProps = {
  ml: ["2rem", '200px', '400px']
}

