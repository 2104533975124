import colors from './colors';
import colorutil from 'color';
import './global.css';

const shadows = require('./shadows');
const theme = {
  colors,
  shadows,
  breakpoints:["40rem","52rem", "64rem"],

  Drawer:{
    variants:{
      default:{
        color: colors.steel,
        hover: colors.tungsten,
        selected: colorutil(colors.tungsten).darken(0.2).string(),
        selectedBg: colorutil(colors.steel).alpha(0.2).string(),
        disabled: colorutil(colors.steel).alpha(0.3).rgb().string(),
      }
    }
  },

  Button:{
    variants:{
      default: {
        color: colors.gray9,
        mute: colorutil(colors.gray9).alpha(0.2).rgb().string(),
        inverse: colors.white,
        muteInverse: colorutil(colors.white).alpha(0.4).rgb().string(),
        border: colors.gray4,
        shadow: '0 0 20px rgba(0,0,0,0.19)'
      },
      primary:{
        color: colors.primary,
        mute: colorutil(colors.primary).alpha(0.2).rgb().string(),
        inverse: colors.onPrimary,
        border: colorutil(colors.primary).alpha(0.7).rgb().string()

      },
      secondary:{
        color: colors.secondary,
        mute: colorutil(colors.secondary).alpha(0.2).rgb().string(),
        inverse: colors.onSecondary,

      },
      danger:{
        color: colors.error,
        mute: colorutil(colors.error).alpha(0.2).rgb().string(),
        inverse: colors.onError
      },
      light:{
        color: colors.white,
        mute: colorutil(colors.white).alpha(0.2).rgb().string(),
        inverse: colors.gray9
      }
    }
  },

  IconButton:{
    variants:{
      default : {
        color: colors.black,
        inverse: colors.white,
      },
      light: {
        color: colors.white,
        inverse: colors.black,
      },
      primary:{
        color: colors.primary,
        inverse: colors.onPrimary,
      },
      danger:{
        color: colors.error,
        inverse: colors.onError
      },
    }
  }
}

export default theme;
