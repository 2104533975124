import React from 'react';
import styled from 'styled-components';
import { FlexProps, space, SpaceProps } from 'styled-system';

export const Row = styled('div')<SpaceProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  ${space}
`

interface CellProps {
  span?: number;
}

export function createCell<T extends FlexProps & SpaceProps>(
  Component:React.ComponentType<any>,
  breakpoints:number[]=[1,2,4],
  m:number=2
):React.ComponentType<T & CellProps>{

  return function(props: T & CellProps){
    let { span, ...otherProps } = props;
    const flex = breakpoints.map((column)=>{
      const weight = Math.min((span || column)/column, 1) * 100
      return `0 1 calc(${weight}% - ${m}rem)`
    });

    return (
      <Component
        flex={flex}
        mx={`${m/2}rem`}
        {...otherProps}/>
    );
  }
}
