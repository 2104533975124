import styled from 'styled-components';
import {
  flex,
  FlexProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  fontSize,
  FontSizeProps,
  fontFamily,
  FontFamilyProps,
  fontWeight,
  FontWeightProps,
  textAlign,
  TextAlignProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  opacity,
  OpacityProps,
} from 'styled-system';

enum WEIGHT {
  LIGHT= 300,
  REGULAR= 400,
  MEDIUM= 500
}

/**
 * Base Text
 */
interface BaseTextProps extends
  ColorProps,
  SpaceProps,
  FontSizeProps,
  FontFamilyProps,
  FontWeightProps,
  LetterSpacingProps,
  LineHeightProps,
  TextAlignProps,
  OpacityProps,
  FlexProps{
    as?: string;
  }

export const Text = styled('div')<BaseTextProps>`
  color: inherited;
  font-weight: ${WEIGHT.REGULAR};
  letter-spacing: 0;
  ${color}
  ${space}
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${letterSpacing}
  ${lineHeight}
  ${textAlign}
  ${flex}
  ${opacity}
`

/**
 * H1
 */
export const H1 = styled(Text)`
  font-size: 96px;
  font-weight: ${WEIGHT.LIGHT};
  letter-spacing: ${-1.5/96}rem;
`
H1.defaultProps = {
  as: 'h1',
  mt: '1.5rem',
  mb: '1.5rem'
}


/**
 * H2
 */
export const H2 = styled(Text)`
  font-size: 60px;
  font-weight: ${WEIGHT.LIGHT};
  letter-spacing: ${-0.5/60}rem;
`
H2.defaultProps = {
  as: 'h2',
  mt: '1rem',
  mb: '1rem'
}

/**
 * H3
 */
export const H3 = styled(Text)`
  font-size: 48px;
  margin: 1rem 0;
`
H3.defaultProps = {
  as: 'h3',
  mt: '1rem',
  mb: '1rem'
}

/**
 * H4
 */
export const H4 = styled(Text)`
  font-size: 34px;
  letter-spacing: ${0.25/34}rem;
`
H4.defaultProps = {
  as: 'h4',
  mt: '0.5rem',
  mb: '0.5rem'
}

/**
 * H5
 */
export const H5 = styled(Text)`
  font-size: 24px;
`
H5.defaultProps = {
  as: 'h5',
  mt: '0.5rem',
  mb: '0.5rem'
}

/**
 * H6
 */
export const H6 = styled(Text)`
  font-size: 20px;
  letter-spacing: ${-0.15/20}rem;
`
H6.defaultProps = {
  as: 'h6',
  mt: '0.5rem',
  mb: '0.5rem'
}

/**
 * Subtitle
 */
export const Subtitle = styled(Text)`
  letter-spacing: ${-0.15/16}rem;
`

/**
 * SubtitleAlt
 */
export const SubtitleAlt = styled(Subtitle)`
  font-size: 14px;
  letter-spacing: ${0.1/14}rem;
`

/**
 * Body
 */
export const Body = styled(Text)`
  letter-spacing: ${0.5/16}rem;
  line-height: 1.5;
`

/**
 * BodyAlt
 */
export const BodyAlt = styled(Body)`
  font-size: 14px;
  letter-spacing: ${0.25/14}rem;
`

/**
 * Button
 */
export const Button = styled(Text)`
  font-size: 14px;
  letter-spacing: ${1.25/14}rem;
  text-transform: uppercase;
`

/**
 * Caption
 */
export const Caption = styled(Text)`
  font-size: 12px;
  letter-spacing: ${0.4/12}rem;
`

/**
 * Overline
 */
export const Overline = styled(Text)`
  font-size: 10px;
  letter-spacing: ${1.5/10}rem;
  text-transform: uppercase;
`
