import React from 'react';
import { Article, Banner, Section } from 'pages/common/Article';

export default function(){
  return(
    <Article>
      <Banner bg="tungsten" title="Super Duplex"/>
      <Section>
        <p>
          Super duplex is also known as Alloy 32750 and S32760, a mixed microstructure of austenite and ferrite which has improved strength over ferritic and austenitic steel grades. Super duplex has a higher chromium and molybdenum content which gives the material greater corrosion resistance than standard duplex grades.
        </p>
        <p>
          Super duplex is mostly used in:
        </p>
        <ul>
          <li>Oil and gas application</li>
          <li>Offshore platform</li>
          <li>Fire-fighting systems</li>
          <li>Heat exchangers</li>
          <li>Desalination plants</li>
          <li>Process and service water systems</li>
          <li>Mechanical and structural components</li>
        </ul>
        <p>
          The benefits of using super duplex:
        </p>
        <ul>
          <li>Excellent resistance to corrosion and erosion</li>
          <li>High thermal conductivity</li>
          <li>High strength</li>
          <li>High energy absorption</li>
          <li>Low thermal expansion</li>
          <li>High thermal conductivity</li>
        </ul>
      </Section>
    </Article>
  )
}
