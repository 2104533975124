import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { SpaceProps, space, color, width, WidthProps, flex, FlexProps} from 'styled-system';
import { ColorProps } from 'components/types/style-system';
import Icon from '../icon/Icon';

export type VariantType = 'filled' | 'outlined';

export type BackgroundProps = {
  variant?: VariantType,
  label?: string,
}

const shake = keyframes`{
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`

function BackgroundBase(props:BackgroundProps & {className?: string}){
  let {label, variant, className} = props;
  variant = variant || 'outlined';
  return (
    <div className={`${className} ${variant}`}>
      {variant === 'outlined' ?(
        <React.Fragment>
          <div className="leading"/>
          <div className="notch">
            <label>{label}</label>
          </div>
          <div className="trailing"/>
        </React.Fragment>
      ):(
        <label>{label}</label>
      )}
    </div>
  )
}

export const Background = styled(BackgroundBase)<BackgroundProps>`
  position: absolute;
  right:0;
  left:0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  color: #999;
  z-index: 10;
  label{
    display: inline-block;
    position: relative;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    font-size: 1rem;
    color: inherit;
    top:0;
    right: initial;
    transform: translateX(0) translateY(0);
  }

  &.filled{
    border-width: 1px;
    border-bottom-style: solid;
    border-radius: 7px 7px 0 0;
    border-color: #CCC;
    background-color: #F0F0F0;
    label{
      transform-origin: top left;
    }
  }

  &.outlined{
    display: flex;
    & > div {
      border-width: 1px;
      border-style: solid;
      border-color: #CCC;
      &.leading{
        border-right-style: none;
        border-radius: 4px 0 0 4px;
        width:12px;
      }
      &.notch{
        position: relative;
        border-left-style: none;
        border-right-style: none;
      }
      &.trailing{
        flex: 1;
        border-left-style: none;
        border-radius: 0 4px 4px 0;
      }
    }
  }
`

/**
 * Container
 */
export type ContainerProps = SpaceProps & ColorProps & WidthProps & FlexProps & {
  variant?: VariantType,
  hasLeadingIcon?: boolean,
  hasTrailingIcon?: boolean,
  active?: boolean,
  hasLabel?: boolean,
  hasFocus?: boolean,
  hasError?: boolean,
  disabled?: boolean,
}

export const Container = styled('section')<ContainerProps>`
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 0 0 0;
  overflow: hidden;
  ${space};
  ${color};
  ${width};
  ${flex};

  .field.singleline {
    height: 3rem;
    line-height: 3rem;
  }

  .field.multiline {
    line-height: 1.5rem;
    & > textarea{
      resize: none;
      margin: 12px;
    }

    ${Background} label{
      line-height: 1.5rem;
      margin-top: 12px;
    }
  }

  .field{
    position: relative;
    display: flex;
    z-index: 11;
    input, textarea{
      background-color: transparent;
      flex: 1;
      border: none;
      outline: none;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      width: 100%;
      z-index: 11;
      &:disabled{
        color: #CCC;
      }
    }

    &>.leading, &>.trailing{
      position: absolute;
      display: inline-block;
      top: 1rem;
      color: inherit;
      z-index: 12;
      line-height: 1rem;
      font-size: 1rem;

      &.leading{
        left:1rem;
      }

      &.trailing {
        right: 1rem;
      }
    }

    ${Icon}{
      font-size: 1rem;
    }

    &:hover{
      ${Background}.filled, ${Background}.outlined  > div {
        border-color: #333;
      }
    }
  }

  & > footer {
    margin-top: 0.2rem;
    padding-left: 1rem;
    font-size: 0.8rem;
    color: #999;
    min-height: 1rem;
  }

  ${(props)=>{
    const { variant, hasLabel, hasFocus, active, hasLeadingIcon, hasTrailingIcon, hasError, disabled, theme } = props;

    const filledVariantStyle = ((variant === 'filled') && hasLabel) && css`
      .field.singleline > input {
        padding-top: 1.2rem;
        line-height: 1.8rem;
      }

      .field.multiline > textarea{
        margin-top: 1.5rem;
      }
    `

    const focusStyle = hasFocus && css `
      ${Background}.filled, ${Background}.outlined > div{
        border-color: ${theme.colors.primary} !important;
        border-width: 2px;
        color: ${theme.colors.primary};
      }
    `

    const errorStyles = hasError && css`
      footer {
        animation: ${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both;
        color: ${theme.colors.error};
      }

      ${Background}.filled, ${Background}.outlined > div{
        border-color: ${theme.colors.error}!important;
        color: ${theme.colors.error};
        ${()=>hasLabel && css`
          label{
            &::after{
              margin-left: 0.2rem;
              content:"*"
            }
          }
        `}
      }
    `

    const activeLabelStyle = (active) && css`
      .singleline {
        ${Background}.filled{
          label{
            transform: translateX(0) translateY(-0.3rem) scale(0.7);
          }
        }

        ${Background}.outlined{
          label{
            left: 0 !important;
            transform: translateX(0) translateY(-50%) scale(0.8);
          }
        }
      }

      .multiline {
        ${Background}.filled{
          label{
            margin-top:0.5rem;
            transform: translateX(0) translateY(-0.2rem) scale(0.7);
          }
        }

        ${Background}.outlined{
          label{
            left: 0 !important;
            margin-top:0;
            transform: translateX(0) translateY(-50%) scale(0.8);
          }
        }
      }

      ${Background}.outlined {
        & > div.notch{
          border-top-style: none;
        }
      }
    `

    const disabledStyle = disabled && css`
      color: #CCC;
      ${Background}{
        color: #CCC;
      }
    `

    return css`
      .field.singleline > input {
        padding-left: ${hasLeadingIcon ? '2.5rem' : '1rem'};
        padding-right: ${hasTrailingIcon ? '2.5rem' : '1rem'};
      }

      .field.multiline > textarea {
        margin-left: ${hasLeadingIcon ? '2.5rem' : '12px'};
        margin-right: ${hasTrailingIcon ? '2.5rem' : '12px'};
      }

      ${Background}.filled{
        label{
          left: ${hasLeadingIcon? '2.5rem':'1rem'};
        }
      }

      ${Background}.outlined{
        label{
          left: ${hasLeadingIcon? '28px':'4px'};
        }
      }

      ${filledVariantStyle}
      ${focusStyle}
      ${activeLabelStyle}
      ${errorStyles}
      ${disabledStyle}
    `
  }}
`
