import styled from 'styled-components';

/**
 * Toggle Mask
 */

export type ToggleMaskProps = {
  toggle?: boolean;
}

const ToggleMask = styled('div')<ToggleMaskProps>`
  position: absolute;
  display: block;
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity: 0.5;
  background-color: #333;
  z-index : 1000;
  ${({toggle})=>{
    return toggle ? {display: 'block'} : {display: 'none'}
  }}
`
export default ToggleMask;

