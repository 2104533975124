import React from 'react';
import styled, {css} from 'styled-components';
import { View } from 'components';
import { createCell } from 'components/grid';
import { FlexProps, SpaceProps, flex, space } from 'styled-system';
import linkable from 'components/utils/hocs/linkable';
import { ProductType } from './data';

type ProductItemProps = FlexProps & SpaceProps & {
  to: string,
  product: ProductType,
}

 function ProductItem(props:ProductItemProps ){
  const {product, ...itemProps} = props;
  return (
    <ItemLink cover={product.cover} {...itemProps}>
      <div className="mask"/>
      <View color="white" p={4}>
        <Title>{product.name}</Title>
        <Caption>{product.desc}</Caption>
      </View>
    </ItemLink>
  );
}

const Title = styled.header`
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  margin: 0 0 0.8rem 0;
`

const Caption = styled.div`
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255,255,255,.7);
`

type ItemProps = {
  cover: string,
}

const Item = styled.div<ItemProps>`
  position: relative;
  display: block;
  color : #FFF;
  margin-bottom: 1rem;
  cursor: pointer;
  background-size: cover;
  background-position: bottom right;
  min-height: 150px;
  border-radius: 8px;
  color: #FFF;
  overflow: hidden;

  & > .mask{
    position: absolute;
    content: " ";
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: transparent;
    transition: all 300ms ease-in;
  }

  &:hover{
    & > .mask{
      background-color: rgba(0,0,0,.5);
    }
  }

  ${(props)=>{
    return css`
      background-image: url(${props.cover});
    `
  }}
  ${flex}
  ${space}
`

const ItemLink = linkable(Item)

export default createCell<ProductItemProps>(ProductItem, [1,2,3])
