import { styleFn } from 'styled-system';

/**
 * @param props - style props
 */
export const colorAsBackground:styleFn = (props: {theme: any, color?:string})=>{
  const { theme, color} = props;
  return {
    backgroundColor: (color && theme.colors[color]) || color
  }
}

export type Size = 'xs' | 's' | 'm' | 'l'

const SIZES = {
  xs: 14,
  s: 20,
  m: 36,
  l: 64
}

/**
 *
 * @param props - style props
 */
export const size: styleFn = (props : {size?:Size})=>{
  const s = ( props.size && SIZES[props.size] ) || SIZES['s'];
  return {
    width: s,
    height: s
  }
}
